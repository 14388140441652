import {Box, TextField, Typography} from "@mui/material"
import React from "react"
import FileUploadButton from "./FileUploadButton"
import * as XLSX from "xlsx"
import {IBusExecl as IBus} from "./NewBus"

const RAILS = [
  "railGarden",
  "railPetite",
  "railHyvaa",
  "railAll",
  "railStrawberry",
  "railStrawberryAll",
  "railAlpaca",
  "railLego",
  "railAlpacaAll",
]
const RAIL_PRODUCTS = ["레남쁘", "레남아", "휘남레", "쁘남레아", "딸남레", "딸남레아", "알남레", "레고레일", "알레남아", "화남레"]
const BLOCK = "------------------------------"
const NOTICE =
  '\n' +
  "**라스트미닛/노쇼로 인한 추가/취소 부탁드립니다.\n" +
  "**취소는 출발 10분 전에만 가능하기에 꼭 10분 전에 취소 부탁드립니다.\n" +
  "**최종발권 후 https://tinyurl.com/4bybr4ay 폼 작성 부탁드립니다.\n------------------------------"

function Bike() {
  const [loadedData, setLoadedData] = React.useState<IBus[]>([])
  const [rails, setRails] = React.useState<Map<string, any>>(new Map())
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    setLoadedData([]);
    setRails(new Map());
    const file = event.target.files[0]

    const binary = await file.arrayBuffer()
    const workbook = XLSX.read(binary, {type: "array"})
    const sheets = workbook.Sheets
    const sheetNames = workbook.SheetNames

    const firstSheet = sheets[sheetNames[0]]
    const datas = XLSX.utils.sheet_to_json(firstSheet) as IBus[]
    parseLoadedData(datas)

  }

  const parseLoadedData = (datas: IBus[]) => {
    datas
      .filter((data) => RAIL_PRODUCTS.includes(data["Product"]))
      .forEach((data) => {
        const {Product, Team, name, People} = data
        const pv = data["Option"]?.startsWith("PV") ? "PV" : "X"

        const key = `${Product}${Team}`
        setRails((prev) => {
          return prev.set(key, [
            ...(prev.has(key) ? prev.get(key) : []),
            {
              name,
              people: People,
              pv,
            },
          ])
        })
      })
    setLoadedData(datas)
  }

  const getTwoSeater = (people: number) => {
    let bike = 0
    let pv = 0
    if (people <= 2) {
      pv += 1
    } else {
      bike = people % 4 !== 0 ? Math.floor(people / 4) + 1 : people / 4
    }

    return {
      bike,
      pv,
    }
  }

  const getFourSeater = (people: number) => {
    if (people === 1) {
      return 0.25
    } else if (people === 2) {
      return 0.5
    } else if (people === 3) {
      return 0.75
    } else {
      let bike = people / 4
      const remainder = people % 4
      if (remainder === 1 || remainder === 2) {
        bike += 0.5
      } else if (remainder === 3) {
        bike += 1
      }
      return bike
    }
  }

  const getSeater = () => {
    const bikes = new Map<string, { bike: number; pv: number }[]>(RAIL_PRODUCTS.map((product) => [product, []]))
    rails.forEach((values, key) => {
      let pvCount = 0
      let bikeCount = 0
      values.forEach((value: any) => {
        const {people, pv} = value
        if (pv === "PV") {
          const {bike, pv} = getTwoSeater(people)
          pvCount += pv
          bikeCount += bike
        } else {
          bikeCount += getFourSeater(people)
          if (people > 2) {
            if (bikeCount === 0.25 || bikeCount === 0.75) {
              bikeCount += 0.25
            }
          }
        }
      })

      if (bikeCount !== 0) {
        bikeCount = Math.ceil(bikeCount)
      }

      const product = key.replace(/\d/g, " ")
      bikes.set(product, [...(bikes.get(product) || []), {bike: bikeCount, pv: pvCount}])
    })

    return bikes
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <FileUploadButton onChange={handleFileChange}/>
      <Box
        style={{
          display: "flex",
          gap: 16,
        }}
      >
        <Box flex="1">
          <Typography variant="h6">필요 대수</Typography>
          <TextField
            id="filled-multiline-flexible"
            label="Multiline"
            multiline
            rows={30}
            fullWidth
            variant="filled"
            value={
              Array.from(getSeater())
                .filter(([key, value]) => value.length > 0)
                .map(([key, values], index) => {
                  let text = `${key}\n`
                  let totalPV = 0
                  let totalBike = 0
                  values.forEach((value, index) => {
                    totalPV += value.pv
                    totalBike += value.bike

                    text = text.concat(
                      `${key}${index + 1} : 2인승 ${value.pv}대 / 4인승 ${value.bike}대\n`
                    )
                  })

                  text = text.concat(`TOTAL : 2인승 ${totalPV}대 / 4인승 ${totalBike}대\n`)

                  return text
                })
                .join("\n\n")}
          />
        </Box>
        <Box flex="1">
          <Typography variant="h6">공지용 문구</Typography>
          <TextField
            id="filled-multiline-flexible"
            label="Multiline"
            multiline
            rows={30}
            fullWidth
            variant="filled"
            value={Array.from(getSeater())
              .filter(([key, value]) => value.length > 0)
              .map(([key, values], index) => {
                let text = `[${key.trim()}]\n**레일바이크 시 분**\n예약번호:\n`
                let totalPV = 0
                let totalBike = 0

                values.forEach((value, index) => {
                  totalPV += value.pv
                  totalBike += value.bike
                })

                text = text.concat(
                  `\n총 구매수량: 2인승 대 / 4인승 대\n총 필요수량: 2인승 ${totalPV}대 / 4인승 ${totalBike}대\n\n예상 필요대수\n`
                )

                values.forEach((value, index) => {
                  text = text.concat(
                    `${key}${index + 1} : 2인승 ${value.pv}대 / 4인승 ${value.bike}대\n`
                  )
                })

                text = text.concat(`${BLOCK}`)

                return text
              })
              .join("\n")
              .concat(NOTICE)}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Bike
