import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";


export default async function parseMyRealTripFile(file: File): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'MRT';
    const agencyCode = cleanString(row['예약번호']);
    const status = cleanString(row['예약상태']);
    if (!agencyCode) return result;
    if(status !== '예약확정') return result;
    const date = dateToDash(row['여행시작일']);
    const product = cleanString(row['상품 이름'])
    const option = cleanString(row['옵션정보']);
    const clientName = cleanString(row['여행자']);
    const people = toNumber(row['수량'])
    const tel = cleanString(row['여행자 연락처'])
    const email = cleanString(row['이메일'])
    const pickupPlace = option;
    const language = 'KO';

    const options = [option]
      .filter(o => !!o)
      .map(cleanString)

    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      language,
      product,
      date,
      people,
      tel,
      email,
      pickupPlace,
      stroller: 'X',
      options: options.map(option => ({option, people})),
    };

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: File) => {
  const fileExtension = file.name?.split('.').pop() ?? 'xlsx';
  const binary = await readFileAsync(file, fileExtension);

  const workbook = XLSX.read(binary, {type: "binary", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];
  return data;
}

function dateToDash(datelike: string) {
  return datelike.split('T')?.[0] ?? datelike;
}

function cleanString(data?: any): string {
  return data?.toString()?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


async function readFileAsync(file: Blob | File, type?: string) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    if (type === 'xlsx' || type == 'xls') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
    reader.onerror = reject;
  })

}
