import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";


export default async function parseBeMyGuestFile(file: File): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'BMG';
    const agencyCode = cleanString(row['Booking Code']);
    const status = cleanString(row['Booking Status']);
    if (!agencyCode) return result;
    if(status !== 'approved') return result;
    const date = cleanString(row['Travel Date']);
    const product = cleanString(row['Product Name'])
    const clientName = cleanString(row['First Name']) +' '+cleanString(row['Last Name']);
    const people = toNumber(row['Total Pax #'])
    const tel = cleanString(row['Customer Phone Code']) + ' ' + cleanString(row['Customer Phone']);
    const email = cleanString(row['Email'])

    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      product,
      date,
      people,
      tel,
      email,
      stroller: 'X',
      options: [],
    };

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: File) => {
  const fileExtension = file.name?.split('.').pop() ?? 'xlsx';
  const binary = await readFileAsync(file, fileExtension);

  const workbook = XLSX.read(binary, {type: "binary", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];
  return data;
}

function cleanString(data?: any): string {
  return data?.toString()?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


async function readFileAsync(file: Blob | File, type?: string) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    if (type === 'xlsx' || type == 'xls') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
    reader.onerror = reject;
  })

}
