import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";
import {findSkiOptions} from "./ski";


function retrieveLanguageFromTourInf(tourInfo: string) {
  if (tourInfo.includes('English') || tourInfo.includes('英语') || tourInfo.includes('英語') || tourInfo.includes('英文')) {
    return 'EN';
  }
  if (tourInfo.includes('Korean') || tourInfo.includes('韩语') || tourInfo.includes('韓語')) {
    return 'KO';
  }
  if (tourInfo.includes('中英双语')) {
    return 'CN,EN';
  }
  return 'CN';
}

function retrieveProduct(tourInfo: string) {
  const split = tourInfo.split('-');
  if(!split?.[1]) return split[0] ?? tourInfo;
  return [split[0], split[1]].join('-');
}

export default async function parseTripcomFile(file: File | Blob): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'TPC';
    const agencyCode = cleanString(row['Trip.com Booking No.']);
    const orderStatus = cleanString(row['Booking Status']);
    if (!agencyCode) return result;
    if (orderStatus.includes('canceled')) return result;
    if (orderStatus.includes('refund')) return result;
    if (!orderStatus) return result;


    const tourInfo = row['Booked Resource Name'];
    const product = retrieveProduct(tourInfo);

    const clientName = cleanString(row['Contact Name']);
    const people = toNumber(row['Tickets Booked']);
    const date = cleanString(row['Date of Use']);
    const email = cleanString(row['Contact Email']);
    const tel = cleanString(row['Contact Mobile'])

    const pickupPlace = cleanString(row['Additional Information']);

    const language = retrieveLanguageFromTourInf(tourInfo);

    const options: string[] = findSkiOptions('TPC', product, tourInfo) ?? [];
    const obj: RawAgencyRow =
      agencyCode in result
        ? result[agencyCode]
        : {
          agency,
          agencyCode,
          clientName,
          language,
          product,
          date,
          people: 0,
          email,
          tel,
          pickupPlace,
          stroller: 'X',
          options: options.map(option => ({option, people: 0})),
        };

    obj.people += people;
    obj.options = obj.options.map((op) => ({option: op.option, people: op.people + people}));

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const secondSheet = sheets[sheetNames[1]]
  const firstData = XLSX.utils.sheet_to_json(firstSheet, {range: 5}) as any[];
  const secondData = XLSX.utils.sheet_to_json(secondSheet, {range: 5}) as any[];
  return [...firstData, ...secondData];
}

function cleanString(data?: any): string {
  const escaped = data?.toString().replace(/.+[\]】]/gi, '');
  return escaped?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}

