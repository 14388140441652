import AgencyRow from "./agency";

const SIMPLE_PROPS = ['agency', 'agencyCode', 'product', 'people', 'language', 'email', 'tel', 'stroller', 'pickupPlace'] as const;
type SimplePropertyName = typeof SIMPLE_PROPS[number];

function isIdentical(v1: string | number, v2: string | number) {
  return v1 === v2
    || v1.toString().toLowerCase().replace(/\s+/gi, '') === v2.toString().toLowerCase().replace(/\s+/gi, '')
    || v1.toString().toLowerCase().replace(/[-+=,. ]/gi, '') === v2.toString().toLowerCase().replace(/[-+=,. ]/gi, '')
    || v1.toString().toLowerCase().replace(/\+\d+/gi, '').trim() === v2.toString().toLowerCase().replace(/\+\d+/gi, '').trim()
    || v1.toString().toLowerCase().replace(/(\+?82)?[ 0]10/, '').trim() === v2.toString().toLowerCase().replace(/(\+?82)?[ 0]10/, '').trim();
}

export default class KintRow extends AgencyRow {
  private compareSimpleProps(agencyRow: AgencyRow, property: SimplePropertyName): CompareMiss | null {
    const kintValue = this[property] + '';
    const agencyValue = agencyRow[property] + '';
    if (agencyValue === 'undefined' || agencyValue === '' || agencyValue === 'null') return null;
    if (!isIdentical(kintValue, agencyValue)) {
      return {
        type: 'MISMATCH_' + property.toUpperCase(),
        property,
        agencyValue,
        kintValue,
      }
    }
    return null;
  }

  private compareDateProps(agencyRow: AgencyRow): CompareMiss | null {
    const [kintYear, kintMonth, kintDate] = this.date?.split('-').map((numberlike) => Number.parseInt(numberlike ?? '0'));
    const [agencyYear, agencyMonth, agencyDate] = agencyRow.date?.split('-').map((numberlike) => Number.parseInt(numberlike ?? '0'));
    if (kintYear !== agencyYear || kintMonth !== agencyMonth || kintDate !== agencyDate) {
      return {
        type: 'MISMATCH_DATE',
        property: 'date',
        agencyValue: agencyRow.date ?? '',
        kintValue: this.date ?? ''
      }
    }
    return null;
  }

  private compareOptions(agencyOptions: AgencyRow['options']): CompareMiss | null {
    const kintOptionMap = Object.fromEntries(this.options.map(o => [o.option, o.people]));
    const agencyOptionMap = Object.fromEntries(agencyOptions.map(o => [o.option, o.people]));
    const kintOptionNames = Object.keys(kintOptionMap);
    const agencyOptionNames = Object.keys(agencyOptionMap);
    const kintOptionNameSet = new Set(kintOptionNames);
    const agencyOptionNameSet = new Set(agencyOptionNames);

    const onlyInKint = kintOptionNames.filter(o => !agencyOptionNameSet.has(o));
    const onlyInAgency = agencyOptionNames.filter(o => !kintOptionNameSet.has(o));
    const misMatching = agencyOptionNames.filter(o => kintOptionMap[o] !== agencyOptionMap[o]);

    if (onlyInKint.length > 0 || onlyInAgency.length > 0 || misMatching.length > 0) {
      return ({
        type: 'MISMATCH__OPTIONS',
        property: 'options',
        agencyValue: agencyOptions.map((o) => `${o.option} X ${o.people}`).sort().join('\n'),
        kintValue: this.options.map((o) => `${o.option} X ${o.people}`).sort().join('\n'),
      })
    }
    return null;
  }


  compare(agencyRow: AgencyRow): CompareResult {
    const simpleProps = SIMPLE_PROPS;
    const missMatchings = simpleProps.map(this.compareSimpleProps.bind(this, agencyRow));
    const dateMatching  = this.compareDateProps(agencyRow);
    const optionMatching = this.compareOptions(agencyRow.options);
    const resulsts: CompareMiss[] = (missMatchings.concat(optionMatching).concat([dateMatching]).filter(result => result !== null) as CompareMiss[])
    return {
      match: resulsts.length === 0,
      agencyRow: agencyRow,
      kintRow: this,
      miss: resulsts
    }
  }
}


export type CompareMiss = {
  type: string,
  property: string,
  agencyValue: string,
  kintValue: string,
}

export type CompareResult = {
  match: boolean,
  agencyRow: AgencyRow,
  kintRow: KintRow,
  miss: CompareMiss[]
}
