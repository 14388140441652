import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";


export default async function parseCoupangFile(file: File): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation: { [agencyCode: string]: RawAgencyRow } = parsed.reduce((result, row) => {
    const agency = 'CP';
    const agencyCode = cleanString(row['예약번호']);
    const status = cleanString(row['상태']);
    if (!agencyCode) return result;
    if (status !== '예약확정') return result;
    const date = dateToDash(row["사용시작일자"]);
    const product = cleanString(row['시설명'])
    const option = cleanString(row['옵션명']);
    const clientName = cleanString(row['주문자명']);
    const tel = cleanString(row['주문자 핸드폰']);
    const email = cleanString(row['주문자 이메일']);

    const pickupPlace = option;

    const options = [option]
      .filter(o => !!o)
      .map(cleanString)

    const obj: RawAgencyRow =
      result[agencyCode]
        ? result[agencyCode]
        : {
          agency,
          agencyCode,
          clientName,
          language: 'KO',
          product,
          date,
          people: 0,
          tel,
          email,
          pickupPlace,
          stroller: 'X',
          options: options.map(option => ({option, people: 0})),
          others: {agencyCodes: [] as string[]}
        };

    obj.people += 1;
    obj.options = obj.options.map(option => ({option: option.option, people: option.people + 1}));
    obj.others?.agencyCodes?.push(cleanString(row['티켓번호']));
    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });

  Object.fromEntries(Object.entries(aggregation).map(([_, row]) => {
    const endAgencyCode = row.others?.agencyCodes?.pop();
    row.agencyCode = endAgencyCode ?? row.agencyCode;
    return [endAgencyCode ?? row.agencyCode, row]
  }));

  return Object.values(aggregation)
    .map((row) => {
      const endAgencyCode = row.others?.agencyCodes?.pop();
      row.agencyCode = endAgencyCode ?? row.agencyCode;
      return row
    });
}

const parseFileData = async (file: File) => {
  const fileExtension = file.name?.split('.').pop() ?? 'xlsx';
  const binary = await readFileAsync(file, fileExtension);

  const workbook = XLSX.read(binary, {type: "binary", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];
  return data;
}

function dateToDash(datelike: string) {
  return datelike.split(' ')[0];
}

function cleanString(data?: any): string {
  return data?.toString().trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


async function readFileAsync(file: Blob | File, type?: string) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    if (type === 'xlsx' || type == 'xls') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
    reader.onerror = reject;
  })

}
