import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";


export default async function parseCreatripFile(file: File): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'CRE';
    const agencyCode = cleanString(row['예약-코드']);
    const status = cleanString(row['상태']);
    if (!agencyCode) return result;
    if(status !== '확정') return result;
    const date = dateToDash(row['예약일']);
    const product = cleanString(row['장소-이름'])
    const clientName = cleanString(row['입력-이름']);
    const people = toNumber(row['수량'])
    const email = cleanString(row['입력-이메일']);

    const language = 'EN';

    const options = [
      row['추가 정보 1'],
      row['추가 정보 2'],
      row['추가 정보 3'],
      row['추가 정보 4'],
      row['추가 정보 5'],
      row['추가 정보 6'],
      row['추가 정보 7'],
      row['추가 정보 8'],
      row['추가 정보 9'],
      row['추가 정보 10'],
      row['추가 정보 11'],
      row['추가 정보 12'],
      row['추가 정보 13'],
      row['추가 정보 14'],
      row['추가 정보 15'],
      row['추가 정보 16'],
      row['추가 정보 17'],
      row['추가 정보 18'],
      row['추가 정보 19'],
      row['추가 정보 20'],
    ]
      .filter(o => !!o)
      .map(cleanString);

    const pickupPlace = options.join('/');

    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      language,
      product,
      date,
      people,
      email,
      pickupPlace,
      stroller: 'X',
      options: options.map(option => ({option, people})),
    };

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: File) => {
  const fileExtension = file.name?.split('.').pop() ?? 'xlsx';
  const binary = await readFileAsync(file, fileExtension);

  const workbook = XLSX.read(binary, {type: "binary", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const targetSheet = sheets['세부상품기준']
  const data = XLSX.utils.sheet_to_json(targetSheet) as any[];
  return data;
}

function dateToDash(datelike: string) {
  return datelike.split(' ')?.[0] ?? datelike;
}

function cleanString(data?: any): string {
  return data?.toString()?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


async function readFileAsync(file: Blob | File, type?: string) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    if (type === 'xlsx' || type == 'xls') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
    reader.onerror = reject;
  })

}
