import {SyntheticEvent, useState} from "react"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import {useAuth} from "../../hooks/auth";
import Reconciliation from "./Reconciliation";
import CrossCheck from "./CrossCheck";
import BepCheck from "./BepCheck";

const SUPER_LIST = ["정산", "에이전시 체크", "Bep"]
const TAB_LIST = ["정산", "에이전시 체크"]

export default function () {
  const [value, setValue] = useState("1");

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }


  const auth = useAuth();
  if (!auth.auth || auth.auth.level > 1) {
    return null;
  }

  const isJeong = auth.auth.email.startsWith('finnjeong@ktourstory.com');
  const tabs = isJeong ? SUPER_LIST : TAB_LIST;

  return (
    <Box sx={{width: "100%", typography: "body1"}}>
      <TabContext value={value}>
        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
          <TabList onChange={handleChange}>
            {tabs.map((tab, index) => (
              <Tab label={tab} value={(index + 1).toString()}/>
            ))}
          </TabList>
        </Box>
        <TabPanel value="1">
          <Reconciliation/>
        </TabPanel>
        <TabPanel value="2">
          <CrossCheck/>
        </TabPanel>
        <TabPanel value={'3'}>
          {
            isJeong
              ? <BepCheck/>
              : null
          }
        </TabPanel>
      </TabContext>
    </Box>
  )
}

