import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";
import {findSkiOptions} from "./ski";


export default async function parseViatorFile(file: File): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'VI';
    const agencyCode = cleanString(row['Booking Reference']);
    const status = cleanString(row['Status']);
    if (!agencyCode) return result;
    if (status !== 'Confirmed' && status !== 'Amended') return result;
    const date = dateToDash(row['Travel Date']);
    const productCode = cleanString(row['Product Code']);
    const product = (cleanString(row['Product Name']) + ' ' + cleanString(row['Tour Grade Title']))
    const option = cleanString(row['Tour Grade Title']);
    const clientName = cleanString(row['Lead traveler Name']);
    const people = toNumber(row['Number of Passengers'])
    const tel = cleanString(row['Lead traveler Contact Info'])


    const pickupPlace = option;

    const addOns = /\d+ x (.+)/.exec(cleanString(row['Add-ons']) ?? '')?.[1] ?? '';
    const language = cleanString(row['Tour Language']).replace(/Chinese.*/gi, 'CN').replace(/English.*/gi, 'EN').replace(/KOREAN.*/gi, 'KO');

    const options = [option, addOns]
      .filter(o => !!o)
      .flatMap((option) => findSkiOptions('VI', cleanString(row['Product Name']) , option) ?? option)
      .map(cleanString)

    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      language,
      product,
      date,
      people,
      tel,
      pickupPlace,
      stroller: 'X',
      options: options.map(option => ({option, people})),
    };

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: File) => {
  const fileExtension = file.name?.split('.').pop() ?? 'xlsx';
  const binary = await readFileAsync(file, fileExtension);

  const workbook = XLSX.read(binary, {type: "binary", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];
  return data;
}

function dateToDash(datelike: string) {
  const date = new Date(datelike);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}

function cleanString(data?: any): string {
  return data?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}


async function readFileAsync(file: Blob | File, type?: string) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    if (type === 'xlsx' || type == 'xls') {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
    reader.onerror = reject;
  })

}
