import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  DataGridPro, DataGridProProps,
  GridColDef, gridFilteredDescendantCountLookupSelector,
  gridFilterModelSelector,
  GridHeaderFilterCellProps, GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import {InputLabel, Select, SelectChangeEvent, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import Icon from "@mui/material/Icon";
import FileOpenIcon from "@mui/icons-material/FileOpen";

import {ReservationRow} from "../type";
import FileUploadButton from "../../helper/FileUploadButton";
import AgencyRow, {RawAgencyRow} from "./agency";
import parseKlookFiles from "./klook";
import {useRead} from "../../../hooks/realtime";
import {Product} from "../../../models/Product";
import KintRow, {CompareResult} from "./kint";
import parseKKDayFiles from "./kkday";
import Button from "@mui/material/Button";
import RedoIcon from '@mui/icons-material/Redo';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CopyIcon from "@mui/icons-material/CopyAll";
import parseGetYourGuideFile from "./getyourguide";
import parseViatorFile from "./viator";
import parseTripcomFile from "./tripcom";
import parseTrazyFile from "./trazy";
import parseCoupangFile from "./coupang";
import parseMyRealTripFile from "./myrealtrip";
import parseBeMyGuestFile from "./bemyguest";
import parseWaugFile from "./waug";
import parseCivitatisFile from "./civitatis";
import parseCreatripFile from "./creatrip";


type DoublCheckRow = {
  id: string,
  hierarchy: string[],
  missType: string,
  agencyValue?: string,
  kintValue?: string,
  date?: string,
  agency?: string,
  agencyCode?: string,
  product?: string,
  clientName?: string,
  people?: number,
  language?: string,
  options?: string,
  email?: string,
  tel?: string,
}

type Matching = {
  count: {
    kint: number,
    agency: number,
    miss: number,
  },
  duplicated: {
    kint: string[],
    agency: string[],
  },
  onlyIn: {
    kint: string[],
    agency: string[],
  },
  compares: [string, CompareResult][]
}


const CopyCell: GridColDef['renderCell'] = (params) => {
  const value = params.formattedValue ?? params.value ?? null;
  const handleClickCopy = useCallback(() => {
    window.navigator.clipboard.writeText(value ?? '').catch(console.error);
  }, [value])

  if (value === null || value === '')
    return value
  return (
    <>
      <IconButton onClick={handleClickCopy}>
        <CopyIcon/>
      </IconButton>
      {value}
    </>
  )
}


function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const {id, field, rowNode, formattedValue,} = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event: any) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  return (
    <Box sx={{ml: rowNode.depth * 4}}>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button onClick={handleClick} tabIndex={-1} size="small">
            {formattedValue}
          </Button>
        ) : (
          <span/>
        )}
      </div>
    </Box>
  );
}


function reservationRowToAgencyRow(row: ReservationRow): KintRow {
  const raw: RawAgencyRow = {
    agency: row.agency,
    agencyCode: row.agencyCode,
    clientName: row.clientName,
    product: row.product,
    date: row.date,
    people: Number.parseInt(row.people.split('(')[0]),
    options: row.option.split('\n')
      .map((os) => {
        const regexMatches = /(.+)\((\d+)\)/gi.exec(os);
        if (!regexMatches) return {option: "Ignore", people: 0};
        const option = regexMatches[1];
        const people = Number.parseInt(regexMatches[2]);
        return ({option, people})
      })
      .filter(o => !!o.people),
    stroller: row.stroller ?? 'X',
    pickupPlace: row.pickupPlace,
    language: row.language,
    email: row.email,
    tel: row.tel,
  }
  return new KintRow(raw);
}


function toMap<R, P extends keyof R>(rows: R[], property: P): {
  duplicate: R[P][],
  countMap: Record<any, number>,
  map: Record<any, R>
} {
  const map = Object.fromEntries(rows.map(row => [row[property], row]));
  const array: R[P][] = rows.map(row => row[property]);
  const countMap: Record<any, number> = {};
  const duplicates: Set<R[P]> = new Set();
  for (const value of array) {
    if (countMap[value]) {
      duplicates.add(value);
      countMap[value as any] += 1;
    } else {
      countMap[value as any] = 1;
    }
  }

  return {
    duplicate: [...duplicates.values()],
    countMap,
    map
  }
}


function matching(kintRows: KintRow[], agencyRows: AgencyRow[], matchingAgency: string, implanted: boolean = true): Matching {

  const kintRowsFiltered = kintRows.filter(({agency}) => agency === matchingAgency);
  const {duplicate: kintDuplicated, map: kintMap} = toMap(kintRowsFiltered, 'agencyCode');
  const {duplicate: agencyDuplicated, map: agencyMap} = toMap(agencyRows, 'agencyCode');
  const kintAgencyCodes = kintRowsFiltered.map(({agencyCode}) => agencyCode);
  const agencyAgencyCodes = agencyRows.map(({agencyCode}) => agencyCode);
  const kintAgencyCodeSet = new Set(kintAgencyCodes);
  const agencyAgencyCodeSet = new Set(agencyAgencyCodes);
  const onlyInKint = kintAgencyCodes.filter(agencyCode => !agencyAgencyCodeSet.has(agencyCode) && !kintDuplicated.includes(agencyCode));
  const onlyInAgency = agencyAgencyCodes.filter(agencyCode => !kintAgencyCodeSet.has(agencyCode) && !agencyDuplicated.includes(agencyCode));
  const onlyInKintSet = new Set(onlyInKint);

  const duplicatedKintSetList = [...new Set(kintDuplicated).values()];
  const duplicatedAgencySetList = [...new Set(agencyDuplicated).values()];
  const onlyInKintSetList = [...new Set(onlyInKint).values()];
  const onlyInAgencySetList = [...new Set(onlyInAgency).values()];


  const kintCompares: [string, CompareResult][] = kintAgencyCodes
    .map((agencyCode): [string, null | CompareResult] => {
      if (onlyInKintSet.has(agencyCode) || duplicatedKintSetList.includes(agencyCode)) {
        return [agencyCode, null];
      }
      const kintRow = kintMap[agencyCode];
      const agencyRow = agencyMap[agencyCode];
      const compareResult = kintRow.compare(agencyRow);
      return [agencyCode, compareResult]
    })
    .filter(r => r[1] !== null && !r[1]?.match) as [string, CompareResult][];

  if (!implanted) {
    return {
      count: {
        kint: kintRowsFiltered.length,
        agency: agencyRows.length,
        miss: kintCompares.length,
      },
      duplicated: {
        kint: duplicatedKintSetList,
        agency: duplicatedAgencySetList,
      },
      onlyIn: {
        kint: [],
        agency: [],
      },
      compares: []
    }
  }

  return {
    count: {
      kint: kintRowsFiltered.length,
      agency: agencyRows.length,
      miss: kintCompares.length,
    },
    duplicated: {
      kint: duplicatedKintSetList,
      agency: duplicatedAgencySetList,
    },

    onlyIn: {
      kint: onlyInKintSetList,
      agency: onlyInAgencySetList,
    },

    compares: kintCompares
  }
}

function matchingToRows(matching: Matching, grouping?: boolean) {
  const kintDuplicatedRows: DoublCheckRow [] = matching.duplicated.kint.map((agencyCode, idx) => ({
    id: agencyCode + 'DUPLICATED',
    hierarchy: ['DUPLICATED', `${agencyCode}`],
    missType: 'DUPLICATED',
    kintValue: agencyCode,
    agencyValue: 'X'

  }));
  const agencyDuplicatedRows: DoublCheckRow [] = matching.duplicated.agency.map((agencyCode, idx) => ({
    id: agencyCode + 'DUPLICATED',
    hierarchy: ['DUPLICATED', `${agencyCode}`],
    missType: 'DUPLICATED',
    kintValue: 'X',
    agencyValue: agencyCode
  }));
  const kintOnlyInRows: DoublCheckRow[] = matching.onlyIn.kint.map((agencyCode, idx) => ({
    id: agencyCode + 'ONLY IN KINT',
    hierarchy: ['ONLY IN KINT', `${agencyCode}`],
    missType: 'ONLY IN KINT',
    kintValue: agencyCode,
    agencyValue: 'X'
  }));
  const agencyOnlyInRows: DoublCheckRow[] = matching.onlyIn.agency.map((agencyCode, idx) => ({
    id: agencyCode + 'ONLY IN AGENCY',
    hierarchy: ['ONLY IN AGENCY', `${agencyCode}`],
    missType: 'ONLY IN AGENCY',
    kintValue: 'X',
    agencyValue: agencyCode
  }));

  function escapeSelector(input: any): string {
    if (typeof input === 'string') {
      const specialChars = /([!"#$%&'()*+,.\/:;<=>?@[\\\]^`{|}~])/g;
      return input.replace(specialChars, '\\$1').replace(/\n/gi, ' ');
    }
    return input;
  }

  const convertToRow = (compareResultTuples: [string, CompareResult][]): DoublCheckRow[] => {
    return compareResultTuples
      .map(([agencyCode, compareResult]) => {
        const missTypes = compareResult.miss.map(miss => miss.type).join(', ');
        const groupRow: DoublCheckRow = {
          id: agencyCode,
          hierarchy: [agencyCode],
          missType: missTypes,
          date: compareResult.kintRow.date,
          agency: compareResult.kintRow.agency,
          agencyCode: compareResult.kintRow.agencyCode,
          product: compareResult.kintRow.product,
          clientName: compareResult.kintRow.clientName,
          people: compareResult.kintRow.people,
          language: compareResult.kintRow.language,
          options: compareResult.kintRow.options.map((o) => `${o.option} X ${o.people}`).sort().join('\n'),
          email: compareResult.kintRow.email,
          tel: compareResult.kintRow.tel,
        }
        const missRows: DoublCheckRow[] = compareResult.miss.map((miss) => ({
          id: agencyCode + miss.type,
          hierarchy: [agencyCode, miss.type],
          missType: miss.type,
          date: compareResult.kintRow.date,
          agency: compareResult.kintRow.agency,
          agencyCode: compareResult.kintRow.agencyCode,
          kintValue: miss.kintValue,
          agencyValue: miss.agencyValue,
        }));
        return [groupRow, ...missRows];
      }).flat(1)
  }
  const convertToGroupingRow = (compareResultTuple: [string, CompareResult][]): DoublCheckRow[] => {
    return compareResultTuple
      .map(([agencyCode, compareResult]) => {
        const missTypeAgencyValueTuples = compareResult.miss.map((miss) => [miss.type, miss.agencyValue, miss.kintValue]);
        const rows: DoublCheckRow[] = missTypeAgencyValueTuples.map(([missType, agencyValue, kintValue]) => ({
          id: `${agencyCode}-${missType}`,
          hierarchy: [missType, agencyCode],
          missType: missType,
          agencyValue: agencyValue,
          kintValue: kintValue,
          date: compareResult.kintRow.date,
          agency: compareResult.kintRow.agency,
          agencyCode: compareResult.kintRow.agencyCode,
          product: compareResult.kintRow.product,
          clientName: compareResult.kintRow.clientName,
          people: compareResult.kintRow.people,
          language: compareResult.kintRow.language,
          options: compareResult.kintRow.options.map((o) => `${o.option} X ${o.people}`).sort().join('\n'),
          email: compareResult.kintRow.email,
          tel: compareResult.kintRow.tel,
        }));
        return [...rows];
      })
      .flat(1)
  }

  const compareRows: DoublCheckRow[] = grouping ? convertToGroupingRow(matching.compares) : convertToRow(matching.compares);

  return [...compareRows, ...kintDuplicatedRows, ...agencyDuplicatedRows, ...kintOnlyInRows, ...agencyOnlyInRows,];
}


function ProductFilter(props: GridHeaderFilterCellProps & { productNames: string[] }) {
  const {colDef, productNames} = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({field}) => field === colDef.field),
    [colDef.field, filterModel.items],
  );

  const getDefaultFilter = (field: string) => ({field, operator: 'isAnyOf'});

  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] ?? getDefaultFilter(colDef.field)),
        value: event.target.value,
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? [];
  const label = 'is any of';

  return (
    <FormControl variant="standard" sx={{m: 1, minWidth: 120}} fullWidth>
      <InputLabel id="select-is-admin-label">Select</InputLabel>
      <Select
        labelId="select-is-admin-label"
        id="select-is-admin"
        multiple
        value={value}
        onChange={handleChange}
        label={'Select'}
      >
        {
          productNames.map((p, idx) => (
            <MenuItem key={p + idx} value={p}>{p}</MenuItem>
          ))
        }

      </Select>
    </FormControl>
  );
}

function useRows<RT, RRT = RT>(parser: (files: File) => Promise<RRT[]>, map: (rrt: RRT) => RT) {
  const [files, setFiles] = useState<(File)[]>([]);
  const [rows, setRows] = useState<RT[]>([]);
  useEffect(() => {
    if (files) {
      Promise.all(files.map(async (file) => await parser(file)))
        .then(results => {
          return results.flat(1)
        })
        .then((rrts) => rrts.map(map))
        .then(setRows)
      // .catch((e) => {
      //   console.error(e);
      //   alert('파일 처리에 실패했습니다.')
      // });
    }
  }, [files, map]);

  return {
    setFiles,
    setRows,
    rows,
    files,
    hasFiles: files?.length > 0
  }
}


function useProducts(refreshKey: string,) {
  const {data: products} = useRead<{ [productId: string]: Product }>('/product', refreshKey);
  const possibleMap = useMemo(() => Object.fromEntries(Object.values(products ?? {}).map(p => (p.possibles ?? []).concat([p.id, p.name]).map(possible => [possible, p])).flat(1)), [products]);
  const find = useCallback((possible: string) => possibleMap[possible], [possibleMap])
  return {find}
}


export default function (props: {
  loadKey: string,
  onRefresh: () => void,
  reservationRows: ReservationRow[],
  onClickReservationRow: (row: GridRowParams) => void,
  onRowSelectionModel: (newRowSelectionModel: GridRowSelectionModel) => void,
  rowSelectionModel: GridRowSelectionModel,
}) {
  const {loadKey, onRefresh, reservationRows, onClickReservationRow, onRowSelectionModel, rowSelectionModel} = props;
  const [keyword, setKeyword] = useState<string | null>(null);
  const [openLevel, setOpenLevel] = useState<number>(6);
  const [grouping, setGrouping] = useState<boolean>(true);
  const {find: findProduct} = useProducts(loadKey);
  const {data: areas} = useRead('/pickup', loadKey);

  const findPickup = useCallback((agencyPickup: string | undefined) => {
    if (!agencyPickup) return agencyPickup;
    return Object.values(areas ?? {}).reduce((result, area) => {
      const found: any = Object.values(area).find((pickup: any) => {
        const possibles = pickup.possibles ?? [];
        return !!possibles?.find((possible: any) => agencyPickup?.toLowerCase().includes(possible?.toLowerCase() ?? ''));
      });
      return found?.place ?? result;
    }, agencyPickup);
  }, [areas]);

  const agencyProductMapping = useCallback((agencyRow: AgencyRow): AgencyRow => {
    agencyRow.pickupPlace = findPickup(agencyRow.pickupPlace);

    const matchingProduct = findProduct(agencyRow.product);
    if (!matchingProduct) {
      return agencyRow;
    }
    console.log(matchingProduct, matchingProduct.option);
    const optionNameTuples: [string, string][] = ((matchingProduct.option ?? []) as {
      option: string,
      possibles: string[]
    }[]).flatMap(o => o.possibles?.map((possible) => [possible, o.option] as [string, string]) ?? [])
    const tuplesWithoutIgnore = optionNameTuples.filter(o => o[0].toLowerCase() !== 'ignore');
    const optionNameMap = new Map<string, string>(optionNameTuples);
    agencyRow.product = matchingProduct?.name ?? agencyRow.product;
    agencyRow.options = tuplesWithoutIgnore.length > 0
      ? agencyRow.options
        .map(({option, people}) => {
          return ({
            option: optionNameMap.get(option) ?? option,
            people: people,
          })
        })
        .filter(o => o.option?.toLowerCase() !== 'ignore')
      : [];
    return agencyRow;
  }, [findProduct, findPickup])

  const kintRows = useMemo(() => reservationRows.map(reservationRowToAgencyRow), [reservationRows]);
  const {
    rows: klookRows,
    setFiles: setKlookFiles,
    hasFiles: hasKlookFiles
  } = useRows<AgencyRow>(parseKlookFiles, agencyProductMapping);
  const {
    rows: kkdayRows,
    setFiles: setKKDayFiles,
    hasFiles: haskkdayFiles
  } = useRows<AgencyRow>(parseKKDayFiles, agencyProductMapping);
  const {
    rows: getYourGuideRows,
    setFiles: setGetYourGuideFiles,
    hasFiles: hasGetYourGuideFiles
  } = useRows<AgencyRow>(parseGetYourGuideFile, agencyProductMapping);
  const {
    rows: viatorRows,
    setFiles: setViatorFiles,
    hasFiles: hasViatorFiles
  } = useRows<AgencyRow>(parseViatorFile, agencyProductMapping);
  const {
    rows: tripcomRows,
    setFiles: setTripcomFiles,
    hasFiles: hasTripcomFiles
  } = useRows<AgencyRow>(parseTripcomFile, agencyProductMapping);
  const {
    rows: trazyRows,
    setFiles: setTrazyFiles,
    hasFiles: hasTrazyFiles
  } = useRows<AgencyRow>(parseTrazyFile, agencyProductMapping);
  const {
    rows: creatripRows,
    setFiles: setCreatripFiles,
    hasFiles: hasCreatripFiles
  } = useRows<AgencyRow>(parseCreatripFile, agencyProductMapping);
  const {
    rows: coupnagRows,
    setFiles: setCoupangFiles,
    hasFiles: hasCoupoangFiles
  } = useRows<AgencyRow>(parseCoupangFile, agencyProductMapping);
  const {
    rows: myRealTripRows,
    setFiles: setMyRealTripFiles,
    hasFiles: hasMyRealTripFiles
  } = useRows<AgencyRow>(parseMyRealTripFile, agencyProductMapping);
  const {
    rows: beMyGuestRows,
    setFiles: setBeMyGuestFiles,
    hasFiles: hasBeMyGuestFiles
  } = useRows<AgencyRow>(parseBeMyGuestFile, agencyProductMapping);
  const {
    rows: waugRows,
    setFiles: setWaugFiles,
    hasFiles: hasWaugFiles
  } = useRows<AgencyRow>(parseWaugFile, agencyProductMapping);
  const {
    rows: civitatisRows,
    setFiles: setCiviatatisFiles,
    hasFiles: hasCivitatisFiles,
  } = useRows<AgencyRow>(parseCivitatisFile, agencyProductMapping);

  const klookMatching = useMemo(() => matching(kintRows, klookRows, 'L', hasKlookFiles), [kintRows, klookRows]);
  const kkdayMatching = useMemo(() => matching(kintRows, kkdayRows, 'KK', haskkdayFiles), [kintRows, kkdayRows]);
  const getYourGuideMatching = useMemo(() => matching(kintRows, getYourGuideRows, 'GG', hasGetYourGuideFiles), [kintRows, getYourGuideRows]);
  const viatorMatching = useMemo(() => matching(kintRows, viatorRows, 'VI', hasViatorFiles), [kintRows, viatorRows]);
  const tripcomMatching = useMemo(() => matching(kintRows, tripcomRows, 'TPC', hasTripcomFiles), [kintRows, tripcomRows]);
  const trazyMatching = useMemo(() => matching(kintRows, trazyRows, 'T', hasTrazyFiles), [kintRows, trazyRows]);
  const coupangMatching = useMemo(() => matching(kintRows, coupnagRows, 'CP', hasCoupoangFiles), [kintRows, coupnagRows]);
  const myRealTripMatching = useMemo(() => matching(kintRows, myRealTripRows, 'MRT', hasMyRealTripFiles), [kintRows, myRealTripRows]);
  const beMyGuestMatching = useMemo(() => matching(kintRows, beMyGuestRows, 'BMG', hasBeMyGuestFiles), [kintRows, beMyGuestRows]);
  const civitatisMatching = useMemo(() => matching(kintRows, civitatisRows, 'CV', hasCivitatisFiles), [kintRows, civitatisRows]);
  const waugMatching = useMemo(() => matching(kintRows, waugRows, 'WG', hasWaugFiles), [kintRows, waugRows]);
  const creatripMatching = useMemo(() => matching(kintRows, creatripRows, 'CRE', hasCreatripFiles), [kintRows, creatripRows]);

  const doubleCheckRows = useMemo(() => [
      ...matchingToRows(klookMatching, grouping),
      ...matchingToRows(kkdayMatching, grouping),
      ...matchingToRows(getYourGuideMatching, grouping),
      ...matchingToRows(viatorMatching, grouping),
      ...matchingToRows(tripcomMatching, grouping),
      ...matchingToRows(trazyMatching, grouping),
      ...matchingToRows(coupangMatching, grouping),
      ...matchingToRows(myRealTripMatching, grouping),
      ...matchingToRows(beMyGuestMatching, grouping),
      ...matchingToRows(waugMatching, grouping),
      ...matchingToRows(creatripMatching, grouping)
    ]
      .sort((a, b) => {
          if (a.missType.includes('ONLY') || a.missType.includes('DUPLI')) return 1;
          if (a.missType.includes('PRODUCT')) return -1;
          if (b.missType.includes('PRODUCT')) return 1;
          if (a.missType.includes('OPTION')) return -1;
          if (b.missType.includes('OPTION')) return 1;
          return a.missType > b.missType ? 1 : -1
        }
      ),
    [grouping,
      klookMatching,
      kkdayMatching,
      getYourGuideMatching,
      viatorMatching,
      tripcomMatching,
      trazyMatching,
      coupangMatching,
      myRealTripMatching,
      beMyGuestMatching,
      waugMatching,
      creatripMatching,
    ]);

  const filteredReservationRows = useMemo(() => (
    reservationRows.filter((r) => {
      return !keyword || `${r.clientName}${r.agencyCode}${r.email}${r.tel}${r.messenger}${r.id}${r.memo}`.toLowerCase().includes(keyword.toLowerCase())
    })), [reservationRows, keyword]);

  const productNamesIncluded = useMemo(() => (
    [...new Set(reservationRows.map((r) => {
      return r.product
    })).values()].sort()), [reservationRows, keyword]);

  const handleChangeFileBuilder = (setter: (files: (File)[]) => void): React.InputHTMLAttributes<HTMLInputElement>['onChange'] =>
    (event) => {
      if (!event.target.files) return;
      setter([...event.target.files]);
    }

  const handleToggleOpenLevel = () => {
    setOpenLevel((prev) => {
      switch (prev) {
        case 2:
          return 10;
        default:
          return prev - 4;
      }
    })
  }

  const handleRedo = () => {
    onRefresh();
  }

  const toggleGrouping = (_: any, value: boolean) => {
    setGrouping(value);
  }
  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Group',
    width: 150,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params}/>
  };

  const getTreeDataPath = useCallback((row: DoublCheckRow) => row.hierarchy, [])

  const ReservationColumns: GridColDef[] = [
    {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
    {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {
      field: 'product',
      type: 'singleSelect',
      headerName: 'PRODUCT',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderHeaderFilter: (params) => <ProductFilter {...params} productNames={productNamesIncluded}/>
    },
    {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {
      field: 'option', headerName: 'OPTION', minWidth: 500, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return (<Typography variant={'body2'} whiteSpace={'pre-wrap'}>{params.formattedValue}</Typography>)
      }
    },
    {field: 'stroller', headerName: 'STROLLER', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
  ]

  const DoubleCheckColumns: GridColDef[] = [
    {field: 'missType', headerName: 'TYPE', minWidth: 200, align: 'center', headerAlign: 'center'},
    {
      field: 'agencyValue',
      headerName: 'AGENCY VALUE',
      minWidth: 300,
      headerAlign: 'center',
      renderCell: CopyCell
    },
    {
      field: 'kintValue',
      headerName: 'KINT VALUE',
      minWidth: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: CopyCell
    },
    {field: 'agency', headerName: 'AGENCY', align: 'center', width: 50, headerAlign: 'center'},
    {
      field: 'agencyCode',
      headerName: 'AGENCY CODE',
      align: 'center',
      minWidth: 200,
      headerAlign: 'center',
      renderCell: CopyCell
    },
    {field: 'date', headerName: 'DATE', align: 'center', headerAlign: 'center'},
    {field: 'product', headerName: 'PRODUCT', align: 'center', headerAlign: 'center'},
    {
      field: 'clientName',
      headerName: 'CLIENT',
      align: 'center',
      minWidth: 200,
      headerAlign: 'center',
      renderCell: CopyCell
    },
    {field: 'people', headerName: 'PEOPLE', align: 'center', headerAlign: 'center'},
    {
      field: 'options', headerName: 'OPTIONS', align: 'center',
      minWidth: 300,
      headerAlign: 'center'
    },
    {field: 'language', headerName: 'LANGUAGE', align: 'center', headerAlign: 'center'},
    {field: 'email', headerName: 'EMAIL', align: 'center', headerAlign: 'center', renderCell: CopyCell},
    {field: 'tel', headerName: 'TEL', align: 'center', headerAlign: 'center', renderCell: CopyCell},
  ]
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'klook'}
                        title={'KLOOK'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${klookMatching.count.agency}/${klookMatching.count.kint}(${klookMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setKlookFiles)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'kkday'}
                        title={'KKDAY'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${kkdayMatching.count.agency}/${kkdayMatching.count.kint}(${kkdayMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setKKDayFiles)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'getyourguide'}
                        title={'GET YOUR GUIDE'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${getYourGuideMatching.count.agency}/${getYourGuideMatching.count.kint}(${getYourGuideMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setGetYourGuideFiles)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'viator'}
                        title={'VIATOR'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${viatorMatching.count.agency}/${viatorMatching.count.kint}(${viatorMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setViatorFiles)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'tripcom'}
                        title={'TRIP.COM'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${tripcomMatching.count.agency}/${tripcomMatching.count.kint}(${tripcomMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setTripcomFiles)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'trazy'}
                        title={'TRAZY'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${trazyMatching.count.agency}/${trazyMatching.count.kint}(${trazyMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setTrazyFiles)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'civitatis'}
                        title={'CIVITATIS'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${civitatisMatching.count.agency}/${civitatisMatching.count.kint}(${civitatisMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setCiviatatisFiles)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'beMyGuest'}
                        title={'BE MY GUEST'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${beMyGuestMatching.count.agency}/${beMyGuestMatching.count.kint}(${beMyGuestMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setBeMyGuestFiles)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'creatrip'}
                        title={'Creatrip'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${creatripMatching.count.agency}/${creatripMatching.count.kint}(${creatripMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setCreatripFiles)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'coupang'}
                        title={'COUPANG'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${coupangMatching.count.agency}/${coupangMatching.count.kint}(${coupangMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setCoupangFiles)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'myrealtrip'}
                        title={'MY REAL TRIP'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${myRealTripMatching.count.agency}/${myRealTripMatching.count.kint}(${myRealTripMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setMyRealTripFiles)}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <FileUploadButton
                        multiple
                        id={'wagu'}
                        title={'WAGU'}
                        variant={'text'}
                        startIcon={<FileOpenIcon/>}
                        label={`${waugMatching.count.agency}/${waugMatching.count.kint}(${waugMatching.count.miss})`}
                        onChange={handleChangeFileBuilder(setWaugFiles)}
                      />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={handleRedo}
                          variant={'contained'}
                          sx={{
                            height: '100%'
                          }}>
                    {`Redo`}
                    <RedoIcon/>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={openLevel}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  height: '70vh',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    sx={{
                      width: '200px'
                    }}
                    variant={'standard'}
                    placeholder={'search'}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value ?? null)}
                  />
                  <IconButton onClick={handleToggleOpenLevel}>
                    <Icon>
                      {
                        openLevel === 2
                          ? 'arrow_forward_ios'
                          : 'arrow_back_ios'
                      }
                    </Icon>
                  </IconButton>
                </Box>
                <DataGridPro
                  checkboxSelection
                  unstable_headerFilters
                  columns={ReservationColumns}
                  rows={filteredReservationRows}
                  getRowHeight={(params) => {
                    const options = (params.model.option ?? '').split('\n');
                    return 44 + Math.max(1, options.length) * 20;
                  }}
                  onRowClick={onClickReservationRow}
                  onRowSelectionModelChange={onRowSelectionModel}
                  rowSelectionModel={rowSelectionModel}
                  disableRowSelectionOnClick
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12 - openLevel}>
          <Card>
            <CardContent>
              <Box
                sx={{height: '70vh', mt: 2}}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'end'
                  }}
                >
                  <FormControlLabel
                    label={'Grouping'}
                    control={<Switch checked={grouping} onChange={toggleGrouping}/>}
                  />
                </Box>
                <DataGridPro
                  treeData
                  checkboxSelection
                  disableRowSelectionOnClick
                  defaultGroupingExpansionDepth={2}
                  rows={doubleCheckRows}
                  columns={DoubleCheckColumns}
                  getTreeDataPath={getTreeDataPath}
                  groupingColDef={groupingColDef}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}


