import {DateRange, DateRangePicker, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {Product} from "../../../models/Product";
import {useRead, useReadKeyStartAtEndAt} from "../../../hooks/realtime";
import {Operation} from "../../../models/Operation";
import {AgencyFileDatum, KintPriceDatum} from "../CrossCheck/types";
import {parseKintPriceFile} from "../CrossCheck/ktourstory";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FileUploadButton from "../../helper/FileUploadButton";
import {DataGridPro, GridColDef, GridToolbar} from "@mui/x-data-grid-pro";
import parseKlookFile from "../CrossCheck/klook";
import parseKKdayFile from "../CrossCheck/kkday";
import parseTrazyFile from "../CrossCheck/trazy";
import parseViatorFile from "../CrossCheck/viator";
import parseGGFile from "../CrossCheck/getyourguide";
import parseCtripFile from "../CrossCheck/ctrip";
import parseCVFiles from "../CrossCheck/civitatis";
import parseBeMyGuestFile from "../CrossCheck/bemyguest";
import parseCreatripFile from "../CrossCheck/creatrip";
import parsePelago from "../CrossCheck/pelago";
import {Reservation, ReservationBase} from "../../../models/Reservation";


type ProductPriceDatum = KintPriceDatum;
type ProductPriceRow = {
  id: string,
  product: string,
  direct: number,
  klookAdult: number,
  klookKid: number,
  viatorAdult: number,
  viatorKid: number,
  ctripAdult: number,
  ctripKid: number,
  othersUSDAdult: number,
  othersUSDKid: number,
  othersAdult: number,
  othersKid: number,
}
type Prices = {
  direct: number,
  klookAdult: number,
  klookKid: number,
  viatorAdult: number,
  viatorKid: number,
  ctripAdult: number,
  ctripKid: number,
  othersUSDAdult: number,
  othersUSDKid: number,
  othersAdult: number,
  othersKid: number,
}
type ProductPriced = Product & { prices?: Prices }

type OverrideAgencyFileDatum = AgencyFileDatum & {
  kintProductArea?: string,
  kintProductCategory?: string,
  kintProductId?: string,
  kintProduct?: string,
  kintPeople?: number,
  kintPrice?: number,
  kintPriceUnit?: string,
  kintOption?: string,
}

type AggregatedValues = {
  people: number,
  agencyPriceKRW: number,
  agencyPriceUSD: number,
  kintPriceKRW: number,
  kintPriceUSD: number,
}

const aggregatedColumns: GridColDef[] = [
  {
    field: 'people',
    headerName: '사람 수',
    valueFormatter:(v)=>v.value.toLocaleString()
  },
  {
    field: 'agencyPriceKRW',
    headerName: '에이전시 기준(KRW)',
    valueFormatter:(v)=>v.value.toLocaleString()
  },
  {
    field: 'agencyPriceUSD',
    headerName: '에이전시 기준(USD)',
    valueFormatter:(v)=>v.value.toLocaleString()
  },
  {
    field: 'kintPriceKRW',
    headerName: 'KINT 기준(KRW)',
    valueFormatter:(v)=>v.value.toLocaleString()
  },
  {
    field: 'kintPriceUSD',
    headerName: 'KINT 기준(USD)',
    valueFormatter:(v)=>v.value.toLocaleString()
  }
]

const priceColumns: GridColDef[] = [
  {field: 'product', headerName: '투어', width: 150, editable: true},
  {
    field: 'klookAdult',
    headerName: 'Klook Adult',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'klookKid',
    headerName: 'Klook Kid',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'othersAdult',
    headerName: '기타 Adult',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'othersKid',
    headerName: '기타 Kid',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'viatorAdult',
    headerName: 'viator Adult',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'viatorKid',
    headerName: 'viator Kid',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'ctripAdult',
    headerName: 'Trip.com Adult(USD))',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'ctripKid',
    headerName: 'Trip.com Kid(USD)',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'othersUSDAdult',
    headerName: 'USD Adult',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'othersUSDKid',
    headerName: 'USD Kid',
    width: 120,
    editable: true,
    type: 'number',
  },
  {
    field: 'direct',
    headerName: '직접',
    width: 100,
    editable: true,
    type: 'number'
  },
]


export default function BepCheck() {
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
  const [tempDateRange, setTempDateRange] = React.useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
  const formatDateRanges = dateRange.map(djs => (djs ?? dayjs()).format('YYYY-MM-DD'));
  const {
    data: operations,
    loading
  } = useReadKeyStartAtEndAt<Operation>('/operation', formatDateRanges[0], formatDateRanges[1]);
  const {data: products} = useRead<Record<string, Product>>('/product');
  const productList = useMemo(() => Object.values(products ?? {}), [products]);
  const productPossibleMap = useMemo(() => {
    const possibleProductTuples = productList.flatMap<[string, Product]>((product) => {
      const possibleProduct = product.possibles?.map<[string, Product]>((p) => [p, product]) ?? [];
      return possibleProduct.concat([[product.id, product], [product.name, product]]);
    });
    return new Map<string, Product>(possibleProductTuples);
  }, [productList]);
  const {rows: priceRows, setFiles: setPriceFile, setRows: setFilePrices} = useRows<KintPriceDatum>(parseKintPriceFile);
  const [priceProductsDict, setPriceProductsDict] = useState<Record<string, ProductPriced>>({});

  const kintReservationAgencyCodeDict = useMemo(() => {
    const operationList = Object.values(operations ?? {});
    const tours = operationList.flatMap((operation) => Object.values(operation.tours ?? {}));
    const teams = tours.flatMap((tour) => Object.values(tour.teams ?? {}));
    const reservations = teams.flatMap((team) => Object.values(team.reservations ?? {}));
    return Object.fromEntries(reservations.map((r) => [r.agencyCode, r]));
  }, [operations]);

  const mapping = useCallback((agencyDatum: AgencyFileDatum): OverrideAgencyFileDatum => {
    const agencyCode = agencyDatum.agencyCode;
    const kintReservationDatum = kintReservationAgencyCodeDict?.[agencyCode];
    const kintProduct = kintReservationDatum ? priceProductsDict?.[kintReservationDatum?.productId] : undefined;
    const kintProductPrices = kintProduct?.prices ?? {
      klookAdult: 0,
      klookKid: 0,
      viatorAdult: 0,
      viatorKid: 0,
      ctripAdult: 0,
      ctripKid: 0,
      othersUSDAdult: 0,
      othersUSDKid: 0,
      othersAdult: 0,
      othersKid: 0,
    };

    if (!kintReservationDatum) return {...agencyDatum}
    if (!kintProduct) return {...agencyDatum}

    const kintReservationAdult = kintReservationDatum.adult;
    const kintReservationKid = kintReservationDatum.kid;
    const kintKlookPrice = (kintReservationAdult * kintProductPrices.klookAdult) + (kintReservationKid * kintProductPrices.klookKid);
    const kintCtripPrice = (kintReservationAdult * kintProductPrices.viatorAdult) + (kintReservationKid * kintProductPrices.viatorKid);
    const kintViatorPrice = (kintReservationAdult * kintProductPrices.viatorAdult) + (kintReservationKid * kintProductPrices.viatorKid);
    const kintOtherUSDPrice = (kintReservationAdult * kintProductPrices.othersUSDAdult) + (kintReservationKid * kintProductPrices.othersUSDKid);
    const kintOthersPrice = (kintReservationAdult * kintProductPrices.othersAdult) + (kintReservationKid * kintProductPrices.othersKid);

    const kintPrice =
      kintReservationDatum.agency === 'L' ? kintKlookPrice
        : kintReservationDatum.agency === 'TPC' ? kintCtripPrice
          : kintReservationDatum.agency === 'VI' ? kintViatorPrice
            : kintReservationDatum.agency === 'GG' ? kintOtherUSDPrice
              : kintReservationDatum.agency === 'CV' ? kintOtherUSDPrice
                : kintOthersPrice;

    const kintPriceUnit =
      kintReservationDatum.agency === 'L' ? 'KRW'
        : kintReservationDatum.agency === 'TPC' ? 'KRW'
          : kintReservationDatum.agency === 'VI' ? 'USD'
            : kintReservationDatum.agency === 'GG' ? 'USD'
              : kintReservationDatum.agency === 'CV' ? 'USD'
                : 'KRW'

    return {
      ...agencyDatum,
      kintPrice,
      kintPriceUnit,
      kintProductArea: kintProduct.area,
      kintProductCategory: kintProduct.category,
      kintProductId: kintProduct?.id,
      kintProduct: kintProduct?.name,
      kintPeople: kintReservationAdult + kintReservationKid,
      kintOption: kintReservationDatum?.option?.map(({option, people}) => `${option}X${people}`).join(', ')
    }
  }, [kintReservationAgencyCodeDict, priceProductsDict]);

  const {
    mappedRows: klookFileRows,
    setFiles: setKlookFiles
  } = useRows<OverrideAgencyFileDatum>(parseKlookFile, mapping);
  const {
    mappedRows: kkdayFileRows,
    setFiles: setKKdayFiles
  } = useRows<OverrideAgencyFileDatum>(parseKKdayFile, mapping);
  const {
    mappedRows: trazyFileRows,
    setFiles: setTrazyFiles
  } = useRows<OverrideAgencyFileDatum>(parseTrazyFile, mapping);
  const {
    mappedRows: viatorFileRows,
    setFiles: setViatorFiles
  } = useRows<OverrideAgencyFileDatum>(parseViatorFile, mapping);
  const {mappedRows: ggFileRows, setFiles: setGgFiles} = useRows<OverrideAgencyFileDatum>(parseGGFile, mapping);
  const {mappedRows: tpcFileRows, setFiles: setTpcFiles} = useRows<OverrideAgencyFileDatum>(parseCtripFile, mapping);
  const {mappedRows: cvFileRows, setFiles: setCvFiles} = useRows<OverrideAgencyFileDatum>(parseCVFiles, mapping);
  const {mappedRows: mrtFileRows, setFiles: setMrtFiles} = useRows<OverrideAgencyFileDatum>(parseTrazyFile, mapping);
  const {
    mappedRows: bmgFileRows,
    setFiles: setBmgFiles
  } = useRows<OverrideAgencyFileDatum>(parseBeMyGuestFile, mapping);
  const {mappedRows: creFileRows, setFiles: setCreFiles} = useRows<OverrideAgencyFileDatum>(parseCreatripFile, mapping);
  const {mappedRows: pgFileRows, setFiles: setPgFiles} = useRows<OverrideAgencyFileDatum>(parsePelago, mapping);

  const allMappedRows = useMemo(() => klookFileRows
      .concat(kkdayFileRows)
      .concat(trazyFileRows)
      .concat(viatorFileRows)
      .concat(ggFileRows)
      .concat(tpcFileRows)
      .concat(cvFileRows)
      .concat(mrtFileRows)
      .concat(bmgFileRows)
      .concat(creFileRows)
      .concat(pgFileRows),
    [
      klookFileRows,
      kkdayFileRows,
      trazyFileRows,
      viatorFileRows,
      ggFileRows,
      tpcFileRows,
      cvFileRows,
      mrtFileRows,
      bmgFileRows,
      creFileRows,
      pgFileRows
    ]);

  const aggreagedRows = useMemo(() => {
    const reduced = allMappedRows.reduce((result, row) => {
      const area = row.kintProductArea ?? 'Unknown';
      const category = row.kintProductCategory ?? 'Unknown';
      const product = row.kintProduct ?? 'Unknown';

      const areaKey = `${area}`;
      // const areaCategoryKey = `${area}/${category}`;
      const areaProductKey = `${area}/${product}`;
      const agencyKey = `${area}/${product}/${row.agency}`;
      // const reservationKey = `${area}/${category}/${product}/${row.agency}/${row.agencyCode}`;

      if (!result[areaKey]) {
        result[areaKey] = {
          people: 0,
          agencyPriceKRW: 0,
          agencyPriceUSD: 0,
          kintPriceKRW: 0,
          kintPriceUSD: 0,
        };
      }
      // if (!result[areaCategoryKey]) {
      //   result[areaCategoryKey] = {
      //     people: 0,
      //     agencyPriceKRW: 0,
      //     agencyPriceUSD: 0,
      //     kintPriceKRW: 0,
      //     kintPriceUSD: 0,
      //   }
      // }

      if (!result[areaProductKey]) {
        result[areaProductKey] = {
          people: 0,
          agencyPriceKRW: 0,
          agencyPriceUSD: 0,
          kintPriceKRW: 0,
          kintPriceUSD: 0,
        }
      }

      if (!result[agencyKey]) {
        result[agencyKey] = {
          people: 0,
          agencyPriceKRW: 0,
          agencyPriceUSD: 0,
          kintPriceKRW: 0,
          kintPriceUSD: 0,
        }
      }

      // if (!result[reservationKey]) {
      //   result[reservationKey] = {
      //     people: 0,
      //     agencyPriceKRW: 0,
      //     agencyPriceUSD: 0,
      //     kintPriceKRW: 0,
      //     kintPriceUSD: 0,
      //   }
      // }

      const unit =
        row.agency === 'L' ? 'KRW'
          : row.agency === 'TPC' ? 'KRW'
            : row.agency === 'VI' ? 'USD'
              : row.agency === 'GG' ? 'USD'
                : row.agency === 'CV' ? 'USD'
                  : 'KRW'

      const rowPeople = row.kintPeople ?? 0;
      const rowAgencyPriceKRW = unit === 'KRW' ? (row.price ?? 0) : 0;
      const rowAgencyPriceUSD = unit === 'USD' ? (row.price ?? 0) : 0;
      const rowKintPriceKRW = row.kintPriceUnit === 'KRW' ? (row.kintPrice ?? 0) : 0;
      const rowKintPriceUSD = row.kintPriceUnit === 'USD' ? (row.kintPrice ?? 0) : 0;

      result[areaKey].people += rowPeople;
      result[areaKey].agencyPriceKRW += rowAgencyPriceKRW;
      result[areaKey].agencyPriceUSD += rowAgencyPriceUSD;
      result[areaKey].kintPriceKRW += rowKintPriceKRW;
      result[areaKey].kintPriceUSD += rowKintPriceUSD;

      // result[areaCategoryKey].people += rowPeople;
      // result[areaCategoryKey].agencyPriceKRW += rowAgencyPriceKRW;
      // result[areaCategoryKey].agencyPriceUSD += rowAgencyPriceUSD;
      // result[areaCategoryKey].kintPriceKRW += rowKintPriceKRW;
      // result[areaCategoryKey].kintPriceUSD += rowKintPriceUSD;

      result[areaProductKey].people += rowPeople;
      result[areaProductKey].agencyPriceKRW += rowAgencyPriceKRW;
      result[areaProductKey].agencyPriceUSD += rowAgencyPriceUSD;
      result[areaProductKey].kintPriceKRW += rowKintPriceKRW;
      result[areaProductKey].kintPriceUSD += rowKintPriceUSD;

      result[agencyKey].people += rowPeople;
      result[agencyKey].agencyPriceKRW += rowAgencyPriceKRW;
      result[agencyKey].agencyPriceUSD += rowAgencyPriceUSD;
      result[agencyKey].kintPriceKRW += rowKintPriceKRW;
      result[agencyKey].kintPriceUSD += rowKintPriceUSD;

      // result[reservationKey].people += rowPeople;
      // result[reservationKey].agencyPriceKRW += rowAgencyPriceKRW;
      // result[reservationKey].agencyPriceUSD += rowAgencyPriceUSD;
      // result[reservationKey].kintPriceKRW += rowKintPriceKRW;
      // result[reservationKey].kintPriceUSD += rowKintPriceUSD;

      return result;
    }, {} as Record<string, AggregatedValues>);

    return Object.entries(reduced).map(([key, r]) => ({
      id: key.replace("/", '-'),
      hierarchy: key.split('/'),
      ...r,
    }));
  }, [allMappedRows]);

  const aggreagedAgencyRows = useMemo(() => {
    const reduced = allMappedRows.reduce((result, row) => {
      const area = row.kintProductArea ?? 'Unknown';
      const category = row.kintProductCategory ?? 'Unknown';
      const product = row.kintProduct ?? 'Unknown';

      const areaKey = `${area}`;
      // const areaCategoryKey = `${area}/${category}`;
      const agencyKey = `${area}/${row.agency}`;
      const agencyProductKey = `${area}/${row.agency}/${product}`;
      // const reservationKey = `${area}/${category}/${product}/${row.agency}/${row.agencyCode}`;

      if (!result[areaKey]) {
        result[areaKey] = {
          people: 0,
          agencyPriceKRW: 0,
          agencyPriceUSD: 0,
          kintPriceKRW: 0,
          kintPriceUSD: 0,
        };
      }
      // if (!result[areaCategoryKey]) {
      //   result[areaCategoryKey] = {
      //     people: 0,
      //     agencyPriceKRW: 0,
      //     agencyPriceUSD: 0,
      //     kintPriceKRW: 0,
      //     kintPriceUSD: 0,
      //   }
      // }
      if (!result[agencyProductKey]) {
        result[agencyProductKey] = {
          people: 0,
          agencyPriceKRW: 0,
          agencyPriceUSD: 0,
          kintPriceKRW: 0,
          kintPriceUSD: 0,
        }
      }
      if (!result[agencyKey]) {
        result[agencyKey] = {
          people: 0,
          agencyPriceKRW: 0,
          agencyPriceUSD: 0,
          kintPriceKRW: 0,
          kintPriceUSD: 0,
        }
      }

      // if (!result[reservationKey]) {
      //   result[reservationKey] = {
      //     people: 0,
      //     agencyPriceKRW: 0,
      //     agencyPriceUSD: 0,
      //     kintPriceKRW: 0,
      //     kintPriceUSD: 0,
      //   }
      // }

      const unit =
        row.agency === 'L' ? 'KRW'
          : row.agency === 'TPC' ? 'KRW'
            : row.agency === 'VI' ? 'USD'
              : row.agency === 'GG' ? 'USD'
                : row.agency === 'CV' ? 'USD'
                  : 'KRW'

      const rowPeople = row.kintPeople ?? 0;
      const rowAgencyPriceKRW = unit === 'KRW' ? (row.price ?? 0) : 0;
      const rowAgencyPriceUSD = unit === 'USD' ? (row.price ?? 0) : 0;
      const rowKintPriceKRW = row.kintPriceUnit === 'KRW' ? (row.kintPrice ?? 0) : 0;
      const rowKintPriceUSD = row.kintPriceUnit === 'USD' ? (row.kintPrice ?? 0) : 0;

      result[areaKey].people += rowPeople;
      result[areaKey].agencyPriceKRW += rowAgencyPriceKRW;
      result[areaKey].agencyPriceUSD += rowAgencyPriceUSD;
      result[areaKey].kintPriceKRW += rowKintPriceKRW;
      result[areaKey].kintPriceUSD += rowKintPriceUSD;

      // result[areaCategoryKey].people += rowPeople;
      // result[areaCategoryKey].agencyPriceKRW += rowAgencyPriceKRW;
      // result[areaCategoryKey].agencyPriceUSD += rowAgencyPriceUSD;
      // result[areaCategoryKey].kintPriceKRW += rowKintPriceKRW;
      // result[areaCategoryKey].kintPriceUSD += rowKintPriceUSD;

      result[agencyKey].people += rowPeople;
      result[agencyKey].agencyPriceKRW += rowAgencyPriceKRW;
      result[agencyKey].agencyPriceUSD += rowAgencyPriceUSD;
      result[agencyKey].kintPriceKRW += rowKintPriceKRW;
      result[agencyKey].kintPriceUSD += rowKintPriceUSD;

      result[agencyProductKey].people += rowPeople;
      result[agencyProductKey].agencyPriceKRW += rowAgencyPriceKRW;
      result[agencyProductKey].agencyPriceUSD += rowAgencyPriceUSD;
      result[agencyProductKey].kintPriceKRW += rowKintPriceKRW;
      result[agencyProductKey].kintPriceUSD += rowKintPriceUSD;

      // result[reservationKey].people += rowPeople;
      // result[reservationKey].agencyPriceKRW += rowAgencyPriceKRW;
      // result[reservationKey].agencyPriceUSD += rowAgencyPriceUSD;
      // result[reservationKey].kintPriceKRW += rowKintPriceKRW;
      // result[reservationKey].kintPriceUSD += rowKintPriceUSD;

      return result;
    }, {} as Record<string, AggregatedValues>);

    return Object.entries(reduced).map(([key, r]) => ({
      id: key.replace("/", '-'),
      hierarchy: key.split('/'),
      ...r,
    }));
  }, [allMappedRows]);


  const priceFromFileGridRows = useMemo(() => {
    return priceRows.map<ProductPriceRow>((r, idx) => ({
      id: r.product,
      product: r.product,
      direct: r.direct,
      klookAdult: r.klook.adult,
      klookKid: r.klook.kid,
      viatorAdult: r.viator.adult,
      viatorKid: r.viator.kid,
      ctripAdult: r.ctrip.adult,
      ctripKid: r.ctrip.kid,
      othersUSDAdult: r.othersUSD.adult,
      othersUSDKid: r.othersUSD.kid,
      othersAdult: r.others.adult,
      othersKid: r.others.kid,
    }));
  }, [priceRows]);

  useEffect(() => {
    const pricedProductEntries: [string, Product & {
      prices?: { [key: string]: number }
    }][] = productList.map<[string, ProductPriced]>((product) => {
      const priceRow = priceRows.find((productPriceRow) => productPriceRow.product === product.name);
      if (!priceRow) return [product.id, {...product}];
      const pricedProduct = {
        ...product,
        prices: {
          direct: priceRow.direct,
          klookAdult: priceRow.klook.adult,
          klookKid: priceRow.klook.kid,
          viatorAdult: priceRow.viator.adult,
          viatorKid: priceRow.viator.kid,
          ctripAdult: priceRow.ctrip.adult,
          ctripKid: priceRow.ctrip.kid,
          othersUSDAdult: priceRow.othersUSD.adult,
          othersUSDKid: priceRow.othersUSD.kid,
          othersAdult: priceRow.others.adult,
          othersKid: priceRow.others.kid,
        }
      };
      return [product.id, pricedProduct];
    })
    setPriceProductsDict(Object.fromEntries(pricedProductEntries) as Record<string, ProductPriced>);
  }, [productList, priceRows]);

  const priceProductRows = useMemo(() => {
    return Object.values(priceProductsDict)
      .filter(product => product.status === 'ON')
      .map<ProductPriceRow>((pricedProduct) => ({
        id: pricedProduct.id,
        product: pricedProduct.name,
        ...pricedProduct.prices
      } as ProductPriceRow))
  }, [priceProductsDict]);

  const handleChangeFileBuilder = (setter: (files: (File | Blob)[]) => void): React.InputHTMLAttributes<HTMLInputElement>['onChange'] =>
    (event) => {
      if (!event.target.files) return;
      setter([...event.target.files]);
    }

  const handleTempDateRange = (dr: DateRange<Dayjs>) => {
    setTempDateRange(dr);
  };

  const handleConfirmDateRange = () => {
    setDateRange(tempDateRange);
  };

  const handleFileRowEditCommit = (params: any) => {
    setFilePrices((prevRows) =>
      prevRows.map((row) =>
        row.product === params.id
          ? {
            ...row,
            product: params.product,
            klook: {
              adult: params.klookAdult,
              kid: params.klookKid,
            },
            viator: {
              adult: params.viatorAdult,
              kid: params.viatorKid,
            },
            ctrip: {
              adult: params.ctripAdult,
              kid: params.ctripKid,
            },
            othersUSD: {
              adult: params.othersUSDAdult,
              kid: params.othersUSDKid,
            },
            others: {
              adult: params.othersAdult,
              kid: params.othersKid,
            },
            direct: params.direct,
          }
          : row
      )
    );
  };

  const handleProductPricedRowEditCommit = (params: any) => {
    const id = params.id;
    // setPriceProductsDict((prevState) => {
    //   const prevProduct = prevState[id];
    //   const prevPrices = prevProduct.prices ?? {};
    //   const nextPrices = {
    //     ...prevPrices,
    //     direct: params.direct,
    //     klookAdult: params.klookAdult,
    //     klookKid: params.klookKid,
    //     viatorAdult: params.viatorAdult,
    //     viatorKid: params.viatorKid,
    //     ctripAdult: params.ctripAdult,
    //     ctripKid: params.ctripKid,
    //     othersUSDAdult: params.othersUSDAdult,
    //     othersUSDKid: params.othersUSDKid,
    //     othersAdult: params.othersAdult,
    //     othersKid: params.othersKid,
    //   }
    //   return ({
    //     ...prevState,
    //     [id]: {
    //       ...prevState[id],
    //       prices: nextPrices
    //     }
    //   })
    // })
  }


  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display={'flex'}
            gap={1}
          >
            <DateRangePickerValue onChange={handleTempDateRange} dateRange={dateRange}/>
            {
              loading ?
                <CircularProgress/>
                : <Button onClick={handleConfirmDateRange} variant={'contained'}
                          disabled={dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join()}>
                  {dateRange.map(d => d?.format('YYYY-MM-DD')).join() === tempDateRange.map(d => d?.format('YYYY-MM-DD')).join() ? `0 개` : '불러오기'}
                </Button>
            }
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FileUploadButton
                    id={'prices'}
                    variant={'text'}
                    startIcon={<FileOpenIcon/>}
                    onChange={handleChangeFileBuilder(setPriceFile)} title={'입금가표'}
                    label={(priceFromFileGridRows?.length ?? 0) + '개'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{height: '50vh'}}>
                    <DataGridPro
                      rows={priceProductRows}
                      columns={priceColumns}
                      processRowUpdate={handleProductPricedRowEditCommit}
                      onProcessRowUpdateError={console.error}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{height: '30vh'}}>
                    <DataGridPro
                      rows={priceFromFileGridRows}
                      columns={priceColumns}
                      processRowUpdate={handleFileRowEditCommit}
                      onProcessRowUpdateError={console.error}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FileUploadButton multiple variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setKlookFiles)}
                                    id={'klook'}
                                    title={`Klook(${klookFileRows.length})`}/>
                </Grid>
                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setKKdayFiles)}
                                    id={'kkday'}
                                    title={`KKday(${kkdayFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setViatorFiles)} id={'viator'}
                                    title={`Viator(${viatorFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton multiple variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setGgFiles)} id={'getyourguide'}
                                    title={`GetYourGuide(${ggFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setTpcFiles)} id={'trip.com'}
                                    title={`Trip.com(${tpcFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setCvFiles)} id={'civitatis'}
                                    title={`Civitatis${cvFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setTrazyFiles)} id={'trazy'}
                                    title={`Trazy(${trazyFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setMrtFiles)} id={'myrealtrip'}
                                    title={`MyRealTrip(${mrtFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setBmgFiles)} id={'bemyguest'}
                                    title={`BeMyGuest(${bmgFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setCreFiles)} id={'creatrip'}
                                    title={`Creatrip(${creFileRows.length})`}/>
                </Grid>

                <Grid item xs={2}>
                  <FileUploadButton variant={'text'} startIcon={<FileOpenIcon/>}
                                    onChange={handleChangeFileBuilder(setPgFiles)} id={'pelago'}
                                    title={`pelago(${pgFileRows.length})`}/>
                </Grid>

                <Grid item xs={6}>
                  <DataGridPro treeData getTreeDataPath={(r)=>r.hierarchy} columns={aggregatedColumns} rows={aggreagedRows}  slots={{ toolbar: GridToolbar }} />
                </Grid>

                <Grid item xs={6}>
                  <DataGridPro treeData getTreeDataPath={(r)=>r.hierarchy} columns={aggregatedColumns} rows={aggreagedAgencyRows}  slots={{ toolbar: GridToolbar }} />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}


function useRows<RT>(parser: (files: File | Blob) => Promise<RT[]>, mapper: (rt: RT) => RT = f => f) {
  const [files, setFiles] = useState<(File | Blob)[]>([]);
  const [rows, setRows] = useState<RT[]>([]);
  const [mappedRows, setMappedRows] = useState<RT[]>([]);
  useEffect(() => {
    if (files) {
      Promise.all(files.map(async (file) => await parser(file)))
        .then(results => {
          return results.flat(1)
        })
        .then(setRows)
    }
  }, [files]);

  useEffect(() => {
    setMappedRows(rows.map(mapper));
  }, [rows]);

  return {
    setFiles,
    setRows,
    setMappedRows,
    mappedRows,
    rows,
  }
}


function DateRangePickerValue(props: { dateRange: DateRange<Dayjs>, onChange: (dr: DateRange<Dayjs>) => void }) {
  const {dateRange: value, onChange: setValue} = props;
  return (

    <DateRangePicker
      format={'YY-MM-DD'}
      slots={{field: SingleInputDateRangeField}}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}

