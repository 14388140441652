import React, {Fragment, memo, useCallback, useEffect, useMemo, useState} from "react";

import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';

import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import {Operation, Tour} from "../../../models/Operation";
import {Product} from "../../../models/Product";
import {ReservationBase} from "../../../models/Reservation";
import {User} from "../../../models/User";
import {
  IconButtonProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {logAction, setRealtime, updateRealtime} from "../../../hooks/firebase";
import {defined} from "chart.js/helpers";
import CardHeader from "@mui/material/CardHeader";
import copy from "../../../utils/copy";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import {useListen, useRead} from "../../../hooks/realtime";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useAuth} from "../../../hooks/auth";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";


dayjs.extend(advancedFormat);

const IGNORE_DISPATCH_PRODUCTS = ['광장시장', 'MBC 스튜디오', 'MBC 스튜디오(드라마 리허설)'];
const IGNORE_ANNOUNCE_PRODUCTS = ['MBC 스튜디오', 'MBC 스튜디오(드라마 리허설)'];
const IGNORE_FLAG_PRODUCTS = [
  '광장시장', '내장산(서울)', '대둔산', '설악산단풍', '오대산', '장태산', '한강나이트워크', 'VIP Golf BA Vista', 'VIP Golf Pocheon', 'MBC 스튜디오', 'MBC 스튜디오(드라마 리허설)', 'VIP TOUR (A)', 'Private(서울)', 'VIP TOUR (B)', 'VIP TOUR (C)', 'VIP TOUR (P)', 'VIP Golf Sollago', '진해(서울)'
];
const SKIP_FLAGS: string[] = [];
const NONE_GUIDE_PRODUCTS = ['남이섬셔틀'];
const PRIVATE_IDS = ['Private', 'Vip'];
const DRIVING_CARS = ['스타리아', '쏠라티', 'Hiace', '렌터카']
const TOTAL_FLAG_COUNT = 40;
const SEOUL_ANNOUNCEMENT = `

——————————————
오피스 연락처 01096508388
★ 투어코스/탑승인원/탑승지/탑승시간 전달
★ 투어 전날 저녁 21시까지 채팅 웹 입장 안 하신 손님들께 WhatsApp/Line/Viber 등으로 연락
★ Email 없는 에이전시(T, VI, TE, CV) 같은 경우 손님 연락처 추가하여 직접 연락
★ 동역사 손님 동대문 아닌 ⭐동대문역사문화공원역⭐ 강조, 하루 전 연락 불가 손님 투어 시작 후 연락처 확보 후 채팅 웹 입장 유도
★ 롯데면세점 쿠폰 배부 필수 [회사 단체 번호: 23179785]
——————————————
● 차량 내 음식물 반입 금지 요청
● 기사님 픽업지/시간/투어명/투어 방문 순서 ⭐반드시⭐ 안내
● 기사님 존칭 사용/매너 유지
● 투어 종료 시 손님 개인 소지품 분실 주의 안내 
——————————————
☑ 출근 사진 업로딩 필수, 누락 시 지각비 차감
☑ 투어 시작과 투어 종료 단톡방에 ⭐반드시⭐ 보고
☑ 투어 당일 기상 시 "투어준비중" 단톡방에 보고 
——————————————
※ 예장 주차장 주의사항
※ 버스는 반드시 주차라인에 정차
※ 본인 깃발 번호 사용 필수
※ 버스로 이동 시 반드시 바닥에 표시된 ⭐하얀색 안전선⭐ 따라서 이동, 주차장 가로지르기 금지, 급할 시 버스기사 출입문 이용

`

const BUSAN_ANNOUNCEMENT = `
——————————————
오피스 연락처 01096508388
★ 투어코스/탑승인원/탑승지/탑승시간 전달
★ 투어 전날 저녁 21시까지 채팅 웹 입장 안 하신 손님들께 WhatsApp/Line/Viber 등으로 연락
★ Email 없는 에이전시(T, VI, TE, CV) 같은 경우 손님 연락처 추가하여 직접 연락
——————————————
● 차량 내 음식물 반입 금지 요청
● 기사님 픽업지/시간/투어명/투어 방문 순서 ⭐반드시⭐ 안내
● 기사님 존칭 사용/매너 유지
● 투어 종료 시 손님 개인 소지품 분실 주의 안내 
——————————————
☑ 출근 사진 업로딩 필수, 누락 시 지각비 차감
☑ 투어 시작과 투어 종료 단톡방에 ⭐반드시⭐ 보고
☑ 투어 당일 기상 시 "투어준비중" 단톡방에 보고 
——————————————
`
const TOKYO_ANNOUNCEMENT = `

——————————————
Office Contact: +82 10-9650-8388
★ Provide the tour course, number of passengers, pick-up location, and pick-up time.
★ Contact guests who haven't entered the chat web by 9 PM the day before the tour via WhatsApp, Line, Viber, etc.
★ For agencies without email (T, VI, TE, CV), add the guest's contact information and contact them directly.
——————————————
● Be sure to inform the driver of the pick-up location/time/tour name/tour sequence.
● Use honorifics and maintain manners with the driver.
● At the end of the tour, remind guests to take care of their personal belongings.
——————————————
☑ Upload attendance photo; if missed, late fee will be deducted.
☑ Report in the group chat without fail at the start and end of the tour.
☑ Report in the "Tour Preparation" group chat upon waking up on the day of the tour.

`


function checkVehicleType(vehicle: string): [string, string, string] {
  if (vehicle === '도보') return ['도보', '도보', ''];
  if (vehicle === '스타리아') return ['스타리아', '스타리아', ''];
  if (vehicle === '쏠라티') return ['쏠라티', '쏠라티', ''];
  if (vehicle === '카운티') return ['카운티', '카운티', ''];
  if (vehicle === '28인승') return ['28인승', '버스', ''];
  if (vehicle === '45인승') return ['45인승', '버스', ''];
  if (vehicle === '렌터카') return ['렌터카', '승용차', ''];
  if (vehicle === 'Walking') return ['Walking', 'Walking', ''];
  if (vehicle === 'Hiace') return ['Hiace', 'Hiace', ''];
  if (vehicle === '18-seater Bus') return ['18-seater Bus', '18-seater Bus', ''];
  if (vehicle === '27-seater Bus') return ['27-seater Bus', '27-seater Bus', ''];
  if (vehicle === '28-seater Bus') return ['28-seater Bus', '28-seater Bus', ''];
  if (vehicle === '32-seater Bus') return ['32-seater Bus', '32-seater Bus', ''];
  if (vehicle === '45-seater Bus') return ['45-seater Bus', '45-seater Bus', ''];
  if (vehicle === '49-seater Bus') return ['49-seater Bus', '49-seater Bus', ''];
  return ['', '', ''];
}


function determineVehicleTypeSeoul(people: number, product?: string): string {
  if (product === '광장시장') {
    return ('도보');
  }
  if (people <= 7) return '스타리아'
  if (people <= 15) return ('카운티');
  return ('45인승');
}

function determineVehicleTypeBusan(people: number, product?: string): string {
  if (people <= 7) return ('스타리아');
  if (people <= 15) return ('카운티');
  return ('45인승');
}

function determineVehicleTypeTokyo(people: number, product?: string): string {
  if (people <= 9) return ('Hiace');
  if (people <= 25) return ('27-seater Bus');
  if (people <= 26) return ('28-seater Bus');
  // if (people <= 30) return ('32-seater Bus');
  return ('49-seater Bus');
}

function vehicleAssigner(vehicleDeterminer: (people: number) => string) {
  return function (peopleOrModel: number | string): { model: string, type: string, } {
    const vehicleModel = typeof peopleOrModel === 'string' ? peopleOrModel : vehicleDeterminer(peopleOrModel);
    const [model, type] = checkVehicleType(vehicleModel);
    return {model, type};
  }
}

type Dictionary<T, K extends string = string> = {
  [key in K]: T;
};

type Area = {
  id: string,
  name: string,
  vehicles: string[],
  disableFlag: boolean,
  assignVehicle: (people: number, product?: string) => string,
  pickupNameMap: { [pickupName: string]: string }
  announcement: string,
  dateFormat: string,
  locale: 'ko' | 'en'
}
const AREAS: { [id: string]: Area } = {
  'seoul': {
    id: 'seoul',
    name: 'Seoul',
    vehicles: ['스타리아', '카운티', '28인승', '45인승', '쏠라티', '도보', '렌터카'],
    disableFlag: false,
    assignVehicle: determineVehicleTypeSeoul,
    pickupNameMap: {
      'Hongdae': '홍',
      'Myungdong': '명',
      'Dongdaemoon': '동',
      'Gwanghwamun': '광화문',
      'Jongno': '종로',
      'Yeouido': '여의도',
    },
    locale: 'ko',
    dateFormat: 'M월 D일 dddd',
    announcement: SEOUL_ANNOUNCEMENT
  },
  'busan': {
    id: 'busan',
    name: 'Busan',
    vehicles: ['스타리아', '카운티', '28인승', '45인승', '쏠라티', '도보'],
    disableFlag: true,
    assignVehicle: determineVehicleTypeBusan,
    pickupNameMap: {
      'Busan Station': '부산역',
      'Seomyun': '서면',
      'Haeundaae': '해운대',
    },
    locale: 'ko',
    dateFormat: 'M월 D일 dddd',
    announcement: BUSAN_ANNOUNCEMENT
  },
  'tokyo': {
    id: 'tokyo',
    name: 'Tokyo',
    vehicles: ['Hiace', '27-seater Bus', '28-seater Bus', '49-seater Bus', 'Shared'],
    disableFlag: true,
    assignVehicle: determineVehicleTypeTokyo,
    pickupNameMap: {
      'Tokyo Station': 'Tokyo',
      'Shinjuku-nishiguchi': 'Shinjuku',
    },
    locale: 'en',
    dateFormat: 'dddd, MMMM Do, YYYY',
    announcement: TOKYO_ANNOUNCEMENT
  },
}

type TourTeam = {
  date: string,
  productId: string,
  teamId: string,
  teamIdx: number,
  teamCount: number,
  tourId: string,
  guideIds: string[],
  explorationIds: string[],
  apprenticeshipIds: string[],
  driverGuideIds: string[],
  reservations: ReservationBase[],
  pickup?: Pickup,

  vehicleModel?: string,
  vehicleNumber?: string,
  vehicleRent?: boolean,
  driverName?: string,
  driverContact?: string,
  memo?: string,
  people?: number,
  mergeTeam?: string,
}

function operationToTeams(operation: Operation, date: string): TourTeam[] {
  const tours = operation.tours ?? {};
  return Object.entries(tours).map(([_tourId, tour]) => {
    const _productId = tour.productId;
    return Object.entries(tour.teams ?? {})
      .sort(([_, aTeam], [__, bTeam]) => aTeam.idx < bTeam.idx ? -1 : 1)
      .map(([_teamId, team], idx, teams) => {
        const _reservations = Object.values(team.reservations ?? {});
        const _date = _reservations[0]?.date ?? date;
        const _guideIds = (team.guides ?? []).map(({id}) => id);
        const _appresnticeshipIds = (team.apprenticeships ?? []).map(({id}) => id);
        const _explorationIds = (team.explorations ?? []).map(({id}) => id);
        const _driverGuideIds = (team.drivers ?? []).map(({id}) => id);
        const _mergeTeam = team.dispatch?.mergeTeam;
        const _vehicleModel = team.dispatch?.vehicleModel;
        const _vehicleNumber = team.dispatch?.vehicleNumber;
        const _vehicleRent = team.dispatch?.vehicleRent;
        const _driverName = team.dispatch?.driverName;
        const _driverContact = team.dispatch?.driverContact;
        const _pickup = team.dispatch?.pickup;
        const _people = team.dispatch?.people;
        const _teamCount = teams.length;
        const _dispatchMemo = team.dispatch?.memo;
        return {
          date: _date,
          productId: _productId,
          tourId: _tourId,
          teamId: _teamId,
          teamIdx: idx,
          teamCount: _teamCount,
          guideIds: _guideIds,
          apprenticeshipIds: _appresnticeshipIds,
          explorationIds: _explorationIds,
          driverGuideIds: _driverGuideIds,
          pickup: _pickup,
          reservations: _reservations,
          vehicleModel: _vehicleModel,
          vehicleNumber: _vehicleNumber,
          vehicleRent: _vehicleRent,
          driverName: _driverName,
          driverContact: _driverContact,
          memo: _dispatchMemo,
          people: _people,
          mergeTeam: _mergeTeam,
        }
      });
  }).flat(1);
}

type Pickup = {
  place: string,
  time: string
}

type Guide = User & {
  explorationGuide: boolean,
  apprenticeshipGuide: boolean,
  driverGuide: boolean,
}

type TourTeamRow = {
  date: string
  team: TourTeam,
  tourId: string,
  teamId: string,
  guides: Guide[],
  product: Product,
  reservations: ReservationBase[],

  pickups: Pickup[],
  vehicleModel: string,
  vehicleType: string,
  vehicleNumber?: string,
  vehicleRent?: boolean
  driverName?: string,
  driverContact?: string,
  mergeTeam?: string,
  mergedTeams: TourTeam[],
  people: number,

  teamNumb: number,
  teamCount: number,
  defined?: boolean,
  pinned?: boolean,
  memo: string,

}

function tourTeamToRow(team: TourTeam, teams: TourTeam[], area: Area, products: Dictionary<Product>, users: Dictionary<User>,): TourTeamRow {
  const mergedTeams = teams.filter(otherTeam => otherTeam.mergeTeam === team.teamId);
  const mergedTeamRows = mergedTeams ? mergedTeams.map(mergedTeam => tourTeamToRow(mergedTeam, teams.filter((t) => t.teamId !== mergedTeam.teamId), area, products, users)) : [];

  const defined = !!(
    team.memo || team.pickup || team.people
    || team.vehicleModel || team.vehicleNumber
    || team.driverName || team.driverContact
    || team.mergeTeam);

  const pinned = !!(team.pickup || team.vehicleModel)

  const date = team.date;
  const tourId = team.tourId;
  const teamId = team.teamId;
  const guides = team.guideIds.map((gid) => users[gid]).concat(mergedTeamRows.map(mergedTeam => mergedTeam?.guides ?? []).flat(1)).map((g) => ({
    ...g,
    explorationGuide: team.explorationIds.includes(g.id),
    apprenticeshipGuide: team.apprenticeshipIds.includes(g.id),
    driverGuide: team.driverGuideIds.includes(g.id)
  }))
    .sort((a, b) => {
      if (a.apprenticeshipGuide) {
        if (b.apprenticeshipGuide) return 0;
        if (b.explorationGuide) return -1;
        if (b.driverGuide) return -1;
        return 1;
      }
      if (a.explorationGuide) {
        if (b.apprenticeshipGuide) return 1;
        if (b.explorationGuide) return 0;
        if (b.driverGuide) return -1;
        return 1
      }
      if (a.driverGuide) {
        if (b.apprenticeshipGuide) return 1;
        if (b.explorationGuide) return 1;
        if (b.driverGuide) return 0;
        return 1
      }
      if (b.explorationGuide || b.apprenticeshipGuide || b.driverGuide) return -1;
      return 0;
    });
  const product = products[team.productId];
  const reservations = team.reservations;
  const teamCount = team.teamCount;
  const teamNumb = team.teamIdx + 1;
  const pickups = team.pickup ? [team.pickup] : reducePickups((reservations.concat(mergedTeamRows.map(mergedTeamRow => mergedTeamRow?.reservations ?? []).flat(1))), product);
  const people = team.people ?? (team.reservations.map((r => r.adult + r.kid + r.infant)).reduce((a, b) => a + b, 0)) + (mergedTeamRows.map(mr => mr.people).reduce((a, b) => a + b, 0));
  const vehicleModel = team.vehicleModel ?? area.assignVehicle(people, product.name);
  const [tempVehicleModel, tempVehicleType, tempDriver] = checkVehicleType(vehicleModel);
  const vehicleType = tempVehicleType;
  const vehicleNumber = team.vehicleNumber ?? '';
  const driverName = team.driverName ?? tempDriver;
  const driverContact = team.driverContact ?? '';
  const mergeTeam = team.mergeTeam;
  const memo = team.memo ?? '';
  return {
    date,
    team,
    tourId,
    teamId,
    guides,
    product,
    reservations,
    teamNumb,
    teamCount,
    pickups,
    mergeTeam,
    mergedTeams,
    people,
    vehicleModel,
    vehicleType,
    vehicleNumber,
    driverName,
    driverContact,
    memo,
    defined,
    pinned
  }
}

function tourTeamToRows(
  tourTeams: TourTeam[],
  products: { [productId: string]: Product },
  users: { [userId: string]: User },
  area: Area,
): TourTeamRow[] {
  return tourTeams.map((team, idx, teams) => {
    return tourTeamToRow(team, teams, area, products, users);
  })
}

function reducePickups(reservations: ReservationBase[], product: Product): Pickup[] {
  const pickupSet = reservations.reduce((result, reservation) => {
    result.add(reservation.pickupPlace)
    return result;
  }, new Set<string>);

  const sortedPickups = Object.entries(product.chat?.pickup ?? {})
    .filter(([_, p]) => p.use)
    .sort(([_, a], [__, b]) => {
      return ((a.order ?? 99) - (b.order ?? 99));
    })
    .map(([_, pickup]) => pickup.place)
    .filter(pickup => pickupSet.has(pickup))
    .map((pickup) => {
      const time = (product.winter ? product.chat?.pickup?.[pickup]?.winterTime : product.chat?.pickup?.[pickup]?.time) ?? 'Unknown Time';
      return {place: pickup, time};
    })
  return sortedPickups;
}


export default function NewNewBus(
  {date, operation, products, users, onAction, area: _areaString}:
    {
      date: string,
      operation: Operation,
      products: { [productId: string]: Product },
      users: { [userId: string]: User },
      area: string,
      onAction: (tourId: string, teamId: string) => void
    }) {

  const [show, setShow] = useState<boolean>(true);
  const [showControls, setShowControls] = useState<boolean>(false);

  const area = AREAS[_areaString];

  const [ignoreAnnouncementProducts, setIgnoreAnnouncementProducts] = useState<string[]>(IGNORE_ANNOUNCE_PRODUCTS);
  const [ignoreDispatchProducts, setIgnoreDispatchProducts] = useState<string[]>(IGNORE_DISPATCH_PRODUCTS);
  const [ignoreFlagProducts, setIgnoreFlagProducts] = useState<string[]>(IGNORE_FLAG_PRODUCTS);
  const [skipFlags, setSkipFlags] = useState<string[]>(SKIP_FLAGS);
  const [noneGuideProducts, setNoneGuideProducts] = useState<string[]>(NONE_GUIDE_PRODUCTS);

  const dispatchPath = `/operation/${date}/dispatch`;
  const {data: operationDispatch} = useListen<{
    ignoreAnnouncementProducts?: { array: string[], updatedAt: string },
    ignoreDispatchProducts?: { array: string[], updatedAt: string },
    ignoreFlagProducts?: { array: string[], updatedAt: string },
    skipFlags?: { array: string[], updatedAt: string },
    noneGuideProducts?: { array: string[], updatedAt: string }
  }>(dispatchPath);


  useEffect(() => {
    if (operationDispatch) {
      const ignoreAnnouncementProducts = operationDispatch?.ignoreAnnouncementProducts ? operationDispatch.ignoreAnnouncementProducts.array ?? [] : IGNORE_ANNOUNCE_PRODUCTS;
      const ignoreDispatchProducts = operationDispatch?.ignoreDispatchProducts ? operationDispatch.ignoreDispatchProducts.array ?? [] : IGNORE_DISPATCH_PRODUCTS;
      const ignoreFlagProducts = operationDispatch?.ignoreFlagProducts ? operationDispatch.ignoreFlagProducts.array ?? [] : IGNORE_FLAG_PRODUCTS;
      const skipFlags = operationDispatch?.skipFlags ? operationDispatch.skipFlags.array ?? [] : SKIP_FLAGS;
      const noneGuideProducts = operationDispatch?.noneGuideProducts ? operationDispatch.noneGuideProducts.array ?? [] : NONE_GUIDE_PRODUCTS;
      setIgnoreAnnouncementProducts(ignoreAnnouncementProducts);
      setIgnoreDispatchProducts(ignoreDispatchProducts);
      setIgnoreFlagProducts(ignoreFlagProducts);
      setSkipFlags(skipFlags);
      setNoneGuideProducts(noneGuideProducts);
    }
  }, [operationDispatch]);

  const [tourTeamRows, setTourTeamRows] = useState<TourTeamRow[]>([]);
  const sortedTourTemRowsProductTime = useMemo(() => {
    const sortedTourTeamRows = tourTeamRows.sort((aTeam, bTeam) => {
      const aRepresentativePickup = representativePickups(aTeam.product);
      const bRepresentativePickup = representativePickups(bTeam.product);
      const aTime = aRepresentativePickup?.time ?? '99:99';
      const bTime = bRepresentativePickup?.time ?? '99:99';
      if (aTime > bTime) return 1;
      if (aTime < bTime) return -1;
      if (aTeam.product.id > bTeam.product.id) return 1;
      if (aTeam.product.id < bTeam.product.id) return -1;
      return aTeam.teamNumb - bTeam.teamNumb;
    });

    return sortedTourTeamRows;
  }, [tourTeamRows]);

  const tourTeamRowOptions = useMemo(() => {
    return tourTeamRows
      .filter((ttr) => !ttr.mergeTeam)
      .map((ttr) => ({
        key: ttr.teamId,
        value: ttr.teamId,
        label: `${ttr.product.name}${ttr.teamNumb}(${ttr.guides.map(g => g.name).join(', ')}`
      }))
  }, [tourTeamRows.map(ttr => (`${ttr.product.name}${ttr.teamNumb}(${ttr.guides.map(g => g.name).join(', ')}`)).join()])



  const goingProductNames = [...new Set(tourTeamRows.map(t => t.product.name)).values()]

  useEffect(() => {
    if (!operation || !products || !users || !area) {
      setTourTeamRows([]);
    }
    const tourTeams = operationToTeams(operation, date);
    const tourTeamRows = tourTeamToRows(tourTeams, products, users, area)
    const filteredTourTeamRows = tourTeamRows
      .filter(({
                 date,
                 product,
                 reservations,
                 guides
               }) => product.area.toLowerCase() === area.id.toLowerCase() && (Object.values(reservations).length > 0 || Object.values(guides).length > 0));
    setTourTeamRows(filteredTourTeamRows);
  }, [operation, products, users, area, date]);

  const clearTourTeamProperties = useCallback((tourTeamRow: TourTeamRow) => {
    const path = `/operation/${date}/tours/${tourTeamRow.tourId}/teams/${tourTeamRow.teamId}`;
    const clearData = {
      dispatch: null
    }
    logAction('OPERATION', 'CLEAR DISPATCH', path, `Clear all dispatch information on ${path}`, {
      dispatch: {
        vehicleType: tourTeamRow?.vehicleType ?? '',
        vehicleNumber: tourTeamRow?.vehicleNumber ?? '',
        people: tourTeamRow?.people ?? 0,
        driverName: tourTeamRow?.driverName ?? '',
        driverContact: tourTeamRow?.driverContact ?? '',
        memo: tourTeamRow?.memo,
        mergeTeam: tourTeamRow?.mergeTeam ?? '',
        pickup: tourTeamRow?.pickups?.[0] ?? {},
      }
    });
    updateRealtime(path, clearData).catch(console.error);
  }, [date,]);

  const handleToggleTourTeamRowPin = useCallback((team: TourTeamRow) => {
    const isPinned = team.pinned;
    const dispatchPath = `/operation/${date}/tours/${team.tourId}/teams/${team.teamId}/dispatch`;
    if (isPinned) {
      updateRealtime(dispatchPath, {pickup: null, vehicleModel: null, vehicleNumber: null}).catch(console.error);
    } else {
      const pickup = team.pickups?.[0] ?? null;
      const vehicleModel = team.vehicleModel;
      const vehicleNumber = team.vehicleNumber;
      updateRealtime(dispatchPath, {pickup, vehicleModel, vehicleNumber}).catch(console.error);
    }
  }, [date])

  const handleChangeTourTeamRowProps = useCallback((team: TourTeamRow, property: keyof TourTeamRow, value: any) => {
    const dispatchPath = `/operation/${date}/tours/${team.tourId}/teams/${team.teamId}/dispatch`;
    if (team.mergeTeam && property !== 'mergeTeam') return;
    switch (property) {
      case 'pickups':
        const changePickup = team.product.chat?.pickup?.[value];
        const pickup: Pickup = {
          place: value,
          time: (team.product.winter ? changePickup?.winterTime : changePickup?.time) ?? '99:99'
        };
        const pickups: Pickup[] = [pickup];
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.pickups)} to ${JSON.stringify(pickups)}`, {pickups});
        updateRealtime(dispatchPath, {
          pickup,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, pickups}
          })
        })
        return;
      case 'driverName':
        const driverName = value;
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.driverName)} to ${JSON.stringify(driverName)}`, {driverName});
        updateRealtime(dispatchPath, {
          driverName,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, driverName}
          })
        })
        return;
      case 'driverContact':
        const driverContact = value;
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.driverContact)} to ${JSON.stringify(driverContact)}`, {driverContact});
        updateRealtime(dispatchPath, {
          driverContact,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, driverContact}
          })
        })
        return;
      case 'vehicleModel':
        const [vehicleModel] = checkVehicleType(value);
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.vehicleModel)} to ${JSON.stringify(vehicleModel)}`, {vehicleModel});
        updateRealtime(dispatchPath, {
          vehicleModel,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, vehicleModel}
          })
        })
        return;
      case 'vehicleNumber': {
        const vehicleNumber = value;
        const vehicleModel = team.vehicleModel;
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.vehicleNumber)} to ${JSON.stringify(vehicleNumber)}`, {vehicleNumber});
        updateRealtime(dispatchPath, {
          vehicleNumber,
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {...prev, vehicleNumber}
          })
        })
        return;
      }
      case 'people':
        const people = value;
        const peopleVehicle = area.assignVehicle(people);
        const [peopleVehicleModel] = checkVehicleType(peopleVehicle);
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.people)} to ${JSON.stringify(people)}(${JSON.stringify(peopleVehicleModel)})`, {
          people,
          peopleVehicleModel
        });
        updateRealtime(dispatchPath, {
            people,
            vehicleModel: peopleVehicleModel,
          }
        ).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {
              ...prev,
              vehicleModel: peopleVehicleModel,
              people: value
            }
          })
        })
        return;
      case 'memo':
        const memo = value;
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.memo)} to ${JSON.stringify(memo)}`, {memo});
        updateRealtime(dispatchPath, {
          memo
        }).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {
              ...prev,
              memo
            }
          })
        })
        return;
      case 'mergeTeam':
        const mergeTeam = value;
        logAction('OPERATION', 'UPDATE DISPATCH', dispatchPath, `update ${property} from ${JSON.stringify(team.mergeTeam)} to ${JSON.stringify(mergeTeam)}`, {mergeTeam});
        updateRealtime(dispatchPath, {mergeTeam}).catch(console.error)
        setTourTeamRows((prev) => {
          return prev.map((prev) => {
            if (prev.teamId !== team.teamId) return prev;
            return {
              ...prev,
              mergeTeam
            }
          })
        })
    }
  }, [date, area, setTourTeamRows]);

  const handleChangeIgnoreAnnounceProducts = (_: any, newIgnores: string[]) => {
    setIgnoreAnnouncementProducts(newIgnores);
    updateRealtime(dispatchPath, {
      ignoreAnnouncementProducts: {
        array: newIgnores,
        updatedAt: new Date().toISOString()
      }
    }).catch(console.error);
  }
  const handleChangeIgnoreDispatchProducts = (_: any, newIgnores: string[]) => {
    setIgnoreDispatchProducts(newIgnores);
    updateRealtime(dispatchPath, {
      ignoreDispatchProducts: {
        array: newIgnores,
        updatedAt: new Date().toISOString()
      }
    }).catch(console.error);
  }

  const handleChangeIgnoreFlagProducts = (_: any, newIgnores: string[]) => {
    setIgnoreFlagProducts(newIgnores);
    updateRealtime(dispatchPath, {
      ignoreFlagProducts: {
        array: newIgnores,
        updatedAt: new Date().toISOString()
      }
    }).catch(console.error);
  }

  const handleChangeSkipFlags = (_: any, newSkips: string[]) => {
    const updates = newSkips.sort((a, b) => Number(a) - Number(b));
    setSkipFlags(updates);
    updateRealtime(dispatchPath, {
      skipFlags: {
        array: updates,
        updatedAt: new Date().toISOString()
      }
    }).catch(console.error);
  }

  const handleChangeNoneGuideProducts = (_: any, newNoneGuides: string[]) => {
    setNoneGuideProducts(newNoneGuides);
    updateRealtime(dispatchPath, {
      noneGuideProducts: {
        array: newNoneGuides,
        updatedAt: new Date().toISOString()
      }
    }).catch(console.error);
  }

  if (tourTeamRows?.[0]?.date && tourTeamRows[0].date !== date) return null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
        >
          <FormControlLabel control={<Switch checked={show} onChange={(_, val) => setShow(val)}/>} label={'문구 표시'}/>
          <FormControlLabel control={<Switch checked={showControls} onChange={(_, val) => setShowControls(val)}/>}
                            label={'제외 표시'}/>
        </Stack>
      </Grid>
      {
        showControls && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      options={goingProductNames}
                      value={ignoreDispatchProducts}
                      onChange={handleChangeIgnoreDispatchProducts}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            color={'primary'}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        return (
                          <Typography component={"span"}{...props}>
                            {option}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="배차문구 제외" placeholder="배차문구 제외"/>
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      options={goingProductNames}
                      value={ignoreAnnouncementProducts}
                      onChange={handleChangeIgnoreAnnounceProducts}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            color={'primary'}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        return (
                          <Typography component={"span"}{...props}>
                            {option}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="공지 제외" placeholder="공지 제외"/>
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      options={goingProductNames}
                      value={noneGuideProducts}
                      onChange={handleChangeNoneGuideProducts}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            color={'primary'}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        return (
                          <Typography component={"span"}{...props}>
                            {option}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="셔틀 투어" placeholder="서틀(가이드 없음) 투어"/>
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      options={goingProductNames}
                      value={ignoreFlagProducts}
                      onChange={handleChangeIgnoreFlagProducts}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            color={'primary'}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        return (
                          <Typography component={"span"}{...props}>
                            {option}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="FLAG 제외" placeholder="FLAG 제외"/>
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      options={new Array(TOTAL_FLAG_COUNT).fill(1).map((i, idx) => (i + idx) + '')}
                      value={skipFlags}
                      onChange={handleChangeSkipFlags}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            color={'primary'}
                            {...getTagProps({index})}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        return (
                          <Typography component={"span"}{...props}>
                            {option}
                          </Typography>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="FLAG SKIP" placeholder="FLAG SKIP"/>
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )
      }

      <Grid item xs={!show ? 12 : 8}>
        <Card>
          <CardContent>
            <Typography variant={'caption'}>
              {new Date(operation.tourUpdatedAt ?? '').toLocaleString()}
            </Typography>
            <Table sx={{width: "100%"}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">No</TableCell>
                  <TableCell align="center">투어</TableCell>
                  <TableCell align="center">팀</TableCell>
                  <TableCell align="center">예약인원/수</TableCell>
                  <TableCell align="center">가이드</TableCell>
                  <TableCell align="center">첫 픽업</TableCell>
                  <TableCell align="center">배차 크기</TableCell>
                  <TableCell align="center">차량</TableCell>
                  <TableCell align="center">차량번호</TableCell>
                  <TableCell align="center">운전기사</TableCell>
                  <TableCell align="center">운전기사 연락처</TableCell>
                  <TableCell align="center">합승 투어팀</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  sortedTourTemRowsProductTime.map((t, idx, list) => {
                    return (
                      <TourTeamTableRow
                        key={t.teamId}
                        index={idx}
                        tourTeamRow={t}
                        tourTeamRowOptions={tourTeamRowOptions}
                        area={area}
                        onOpen={onAction}
                        onTogglePin={handleToggleTourTeamRowPin}
                        onChangeTourTeamRowProps={handleChangeTourTeamRowProps}
                        onClearTourTeamProperties={clearTourTeamProperties}
                      />
                    )
                  })
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          {
            show &&
              <>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <DispatchPhrase
                          key={date + area.id + 'dispatch'}
                          tourTeamRows={tourTeamRows.filter(ttr =>
                            !PRIVATE_IDS.find(privateIdPartial => ttr.product.id.includes(privateIdPartial))
                            && !IGNORE_ANNOUNCE_PRODUCTS.includes(ttr.product.name)
                            && !DRIVING_CARS.includes(ttr.vehicleModel ?? '')
                          )}
                          date={date}
                          area={area}
                          tourUpdatedAt={operation?.tourUpdatedAt ?? ''}
                          ignoreProducts={ignoreDispatchProducts}
                          noneGuideProducts={noneGuideProducts}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <AnnouncementPhrase
                          key={date + area.id + 'announcement'}
                          tourTeamRows={tourTeamRows.filter(ttr => !PRIVATE_IDS.includes(ttr.product.id) && !IGNORE_ANNOUNCE_PRODUCTS.includes(ttr.product.name))}
                          date={date}
                          tourUpdatedAt={operation?.tourUpdatedAt ?? ''}
                          area={area}
                          ignoreProducts={ignoreAnnouncementProducts}
                          ignoreFlagProducts={ignoreFlagProducts}
                          noneGuideProducts={noneGuideProducts}
                          skipFlags={skipFlags}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <DrivingTourPhrase
                          key={date + area.id + 'driving'}
                          tourTeamRows={tourTeamRows.filter(ttr =>
                            DRIVING_CARS.includes(ttr.vehicleModel ?? '')
                            && !PRIVATE_IDS.find(privateIdPartial => ttr.product.id.includes(privateIdPartial))
                            && !ttr.product.name.includes('광장시장'))
                          }
                          date={date}
                          area={area}
                          tourUpdatedAt={operation?.tourUpdatedAt ?? ''}
                          ignoreProducts={ignoreDispatchProducts}
                          noneGuideProducts={noneGuideProducts}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <PrivateTourPhrase
                          key={date + area.id + 'private'}
                          tourTeamRows={tourTeamRows.filter(ttr => PRIVATE_IDS.find(privateIdPartial => ttr.product.id.includes(privateIdPartial)))}
                          date={date}
                          area={area}
                          tourUpdatedAt={operation?.tourUpdatedAt ?? ''}
                          ignoreProducts={ignoreDispatchProducts}
                          noneGuideProducts={noneGuideProducts}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

const TourTeamTableRow = memo(_TourTeamTableRow);

function _TourTeamTableRow(props: {
  tourTeamRow: TourTeamRow,
  tourTeamRowOptions: { key: string, value: string, label: string }[],
  onOpen: (tourId: string, teamId: string) => void,
  onTogglePin: (tourTeamRow: TourTeamRow) => void,
  onChangeTourTeamRowProps: (tourTeamRow: TourTeamRow, property: keyof TourTeamRow, value: any) => void,
  onClearTourTeamProperties: (tourTeamRow: TourTeamRow) => void,
  area: Area,
  index: number
}) {
  const {tourTeamRow, tourTeamRowOptions, index, area} = props;
  const {onOpen, onTogglePin, onChangeTourTeamRowProps, onClearTourTeamProperties} = props;
  return (
    <Fragment>
      <TableRow
        sx={{
          "& th": {borderBottom: 'none'},
          "& td:nth-child(-n+12)": {borderBottom: 'none'},
        }}
      >
        <TableCell component="th" scope="row" align="center">
          {index + 1}
        </TableCell>
        <TableCell align={'center'}>
          {tourTeamRow.product.name}({representativePickups(tourTeamRow.product)?.time ?? '99:99'})
        </TableCell>
        <TableCell align={'center'}>
          {tourTeamRow.teamNumb}
        </TableCell>
        <TableCell align={'center'}>
          <Typography
            color={tourTeamRow.reservations.reduce((a, b) => a + b.infant + b.kid + b.adult, 0) !== tourTeamRow.people ? 'error' : 'inherit'}>
            {tourTeamRow.reservations.reduce((a, b) => a + b.infant + b.kid + b.adult, 0)}명/
            {tourTeamRow.reservations.length}개
          </Typography>
        </TableCell>
        <TableCell align={'center'}>
          {tourTeamRow.guides.map(g => (`${g.name}` + (g.explorationGuide ? '(답)' : '') + (g.apprenticeshipGuide ? '(수)' : '') + (g.driverGuide ? '(운)' : ''))).join(', ')}
        </TableCell>

        <TableCell align={'center'}>
          <TextField
            fullWidth
            select
            disabled={!!tourTeamRow.mergeTeam}
            name="pickup"
            value={tourTeamRow.pickups[0]?.place ?? ''}
            SelectProps={{native: true,}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeTourTeamRowProps(tourTeamRow, 'pickups', e.target.value)
            }}
          >
            {
              Object.entries(area.pickupNameMap)
                .map(([value, label]) => (<option key={value} value={value}>{label}</option>))
            }
          </TextField>
        </TableCell>
        <TableCell align={'center'}>
          <TextField
            fullWidth
            select
            disabled={!!tourTeamRow.mergeTeam}
            name="people"
            type={'number'}
            SelectProps={{native: true}}
            value={tourTeamRow.people}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeTourTeamRowProps(tourTeamRow, 'people', Number.parseInt(e.target.value))
            }}
          >
            {(new Array(50).fill(1).map((_, idx) => idx + 1))
              .map(p => (
                <option key={p} value={p}>{p}명</option>))
            }
          </TextField>
        </TableCell>
        <TableCell align={'center'}>
          <TextField
            fullWidth
            select
            disabled={!!tourTeamRow.mergeTeam}
            name="vehicleModel"
            type="text"
            SelectProps={{native: true}}
            value={tourTeamRow.vehicleModel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeTourTeamRowProps(tourTeamRow, 'vehicleModel', e.target.value)
            }}
          >
            {area.vehicles.map(p => (<option key={p} value={p}>{p}</option>))}
          </TextField>
        </TableCell>
        <TableCell align={'center'}>
          <TextField
            fullWidth
            disabled={!!tourTeamRow.mergeTeam}
            name="vehicleNumber"
            type="text"
            value={tourTeamRow.vehicleNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeTourTeamRowProps(tourTeamRow, 'vehicleNumber', e.target.value)
            }}
          />
        </TableCell>
        <TableCell align={'center'}>
          <TextField
            fullWidth
            disabled={!!tourTeamRow.mergeTeam}
            name="driverName"
            type="text"
            value={tourTeamRow.driverName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeTourTeamRowProps(tourTeamRow, 'driverName', e.target.value)
            }}
          />
        </TableCell>
        <TableCell align={'center'}>
          <TextField
            fullWidth
            disabled={!!tourTeamRow.mergeTeam}
            name="driverContact"
            type="text"
            value={tourTeamRow.driverContact}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeTourTeamRowProps(tourTeamRow, 'driverContact', e.target.value)
            }}
          />
        </TableCell>
        <TableCell align={'center'}>
          <TextField
            fullWidth
            select
            name="mergeTeam"
            type="text"
            SelectProps={{native: true}}
            value={tourTeamRow.mergeTeam ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeTourTeamRowProps(tourTeamRow, 'mergeTeam', e.target.value)
            }}
          >
            {
              tourTeamRowOptions.concat([{key:'', value:'', label:''}])
                .filter(ttro => ttro.value !== tourTeamRow.teamId)
                .map((ttro) => <option key={ttro.key} value={ttro.value}>{ttro.label}</option>)
            }
          </TextField>
        </TableCell>
        <TableCell align={'center'} rowSpan={2}>
          <Stack
            flexDirection={'column'}
            gap={'16px'}
          >
            <IconButton
              color={tourTeamRow.pinned ? 'primary' : 'inherit'}
              onClick={(() => {
                onTogglePin(tourTeamRow);
              })}
            >
              <Icon>
                push_pin
              </Icon>
            </IconButton>
            <IconButton
              disabled={!tourTeamRow.defined}
              onClick={() => {

                onClearTourTeamProperties(tourTeamRow)
              }}>
              <Icon>
                restore
              </Icon>
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell align={'center'} rowSpan={2}>
          <IconButton onClick={() => {
            onOpen(tourTeamRow.tourId, tourTeamRow.teamId)
          }}>
            <Icon>
              launch
            </Icon>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell/><TableCell/><TableCell/><TableCell/><TableCell/>
        <TableCell colSpan={7}>
          <TextField
            multiline
            fullWidth
            value={tourTeamRow.memo}
            onChange={(e) => {
              onChangeTourTeamRowProps(tourTeamRow, 'memo', e.target.value);
            }}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

function AnnouncementPhrase({
                              tourTeamRows,
                              area,
                              ignoreProducts,
                              ignoreFlagProducts,
                              noneGuideProducts,
                              skipFlags,
                              date,
                              tourUpdatedAt
                            }: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  tourUpdatedAt: string,
  ignoreProducts: string[],
  ignoreFlagProducts: string[],
  noneGuideProducts: string[],
  skipFlags: string[],
  date: string
}) {

  const {operator} = useAuth();

  const path = `/operation/${date}/dispatch/${area.id}/announcement`;
  const {data} = useRead(path);
  const {data: listenData} = useListen<{ writerName?: string, writerId?: string, updatedAt?: string }>(path);
  const _text = (data as { phrase: string })?.phrase ?? '';
  const [text, setText] = useState<string>(_text);

  const handleUpdatePhrase: TextFieldProps['onChange'] = (e) => {
    const phrase = e.target.value;
    updateRealtime(path, {
      phrase,
      updatedAt: new Date().toISOString(),
      writerId: operator?.id,
      writerName: operator?.name
    }).catch((e) => {
      console.error(e);
      alert('수정사항을 저장할 수 없습니다.')
    });
    const changes = `DISPATCH ANNOUNCEMENT  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
    setText(phrase);
  }

  const handleApply: IconButtonProps['onClick'] = () => {
    if (text && !window.confirm('기존 입력 사항을 덮어쓰시겠습니까?')) return;
    const phrase = announcement(tourTeamRows, area, ignoreProducts, ignoreFlagProducts, noneGuideProducts, skipFlags);
    const updatableRecords = JSON.parse(JSON.stringify(tourTeamRows));
    const records = {updatedAt: new Date().toISOString(), records: updatableRecords};
    setText(phrase);
    (async () => {
      await updateRealtime(path, {
        phrase,
        records,
        updatedAt: new Date().toISOString(),
        writerId: operator?.id,
        writerName: operator?.name
      })
    })()
      .catch((e) => {
        console.error(e);
        alert('수정사항을 저장할 수 없습니다.')
      });
    const changes = `DISPATCH ANNOUNCEMENT by records  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
  }

  useEffect(() => {
    if (!_text) return;
    if (text !== _text)
      setText(_text);
  }, [_text]);


  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={'8px'}
        >
          <Typography variant={'h6'}>
            공지
          </Typography>
          <IconButton onClick={handleApply}>
            <Icon>system_update_alt</Icon>
          </IconButton>
        </Box>
        <IconButton onClick={() => {
          copy(text).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <Stack>
        <Typography variant={'caption'}
                    color={new Date(tourUpdatedAt).getTime() > new Date(listenData?.updatedAt ?? '').getTime() ? 'error' : 'primary'}>
          {listenData?.writerName ?? ''} - {new Date(listenData?.updatedAt ?? '').toLocaleString()}
        </Typography>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        value={text}
        onChange={handleUpdatePhrase}
      />
    </Stack>
  )
}


function DispatchPhrase({tourTeamRows, area, date, ignoreProducts, noneGuideProducts, tourUpdatedAt}: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  tourUpdatedAt: string,
  ignoreProducts: string[],
  noneGuideProducts: string[],
  date: string,
}) {

  const {operator} = useAuth();

  const path = `/operation/${date}/dispatch/${area.id}/dispatch`;
  const {data: readData} = useRead(path);
  const {data: listenData} = useListen<{ writerName?: string, writerId?: string, updatedAt?: string }>(path);

  const _text = (readData as { phrase: string })?.phrase ?? '';
  const [text, setText] = useState<string>(_text);

  const handleUpdatePhrase: TextFieldProps['onChange'] = (e) => {
    const phrase = e.target.value;
    updateRealtime(path, {
      phrase,
      updatedAt: new Date().toISOString(),
      writerId: operator?.id,
      writerName: operator?.name
    }).catch((e) => {
      console.error(e);
      alert('수정사항을 저장할 수 없습니다.')
    });

    const changes = `DISPATCH DISPATCH  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
    setText(phrase);
  }

  const handleApply: IconButtonProps['onClick'] = () => {
    if (text && !window.confirm('기존 입력 사항을 덮어쓰시겠습니까?')) return;
    const phrase = dispatch(tourTeamRows, area, ignoreProducts, noneGuideProducts, false, true, false);
    const updatableRecords = JSON.parse(JSON.stringify(tourTeamRows));
    const records = {updatedAt: new Date().toISOString(), records: updatableRecords};
    setText(phrase);
    (async () => {
      await updateRealtime(path, {
        phrase,
        records,
        updatedAt: new Date().toISOString(),
        writerId: operator?.id,
        writerName: operator?.name
      })
    })()
      .catch((e) => {
        console.error(e);
        alert('수정사항을 저장할 수 없습니다.')
      });
    const changes = `DISPATCH DISPATCH by records  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
  }

  useEffect(() => {
    if (!_text) return;
    if (text !== _text)
      setText(_text);
  }, [_text]);

  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={'8px'}>
          <Typography variant={'h6'}>
            배차
          </Typography>
          <IconButton onClick={handleApply}>
            <Icon>system_update_alt</Icon>
          </IconButton>
        </Box>
        <IconButton onClick={() => {
          copy(text).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <Stack>
        <Typography variant={'caption'}
                    color={new Date(tourUpdatedAt).getTime() > new Date(listenData?.updatedAt ?? '').getTime() ? 'error' : 'primary'}>
          {listenData?.writerName ?? ''} - {new Date(listenData?.updatedAt ?? '').toLocaleString()}
        </Typography>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        value={text}
        onChange={handleUpdatePhrase}
      />
    </Stack>
  )

}


function DrivingTourPhrase({tourTeamRows, area, ignoreProducts, noneGuideProducts, date, tourUpdatedAt}: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  tourUpdatedAt: string,
  ignoreProducts: string[],
  noneGuideProducts: string[],
  date: string
}) {
  const {operator} = useAuth();

  const path = `/operation/${date}/dispatch/${area.id}/driving`;
  const {data} = useRead(path);
  const {data: listenData} = useListen<{ writerName?: string, writerId?: string, updatedAt?: string }>(path);
  const _text = (data as { phrase: string })?.phrase ?? '';
  const [text, setText] = useState<string>(_text);

  const handleUpdatePhrase: TextFieldProps['onChange'] = (e) => {
    const phrase = e.target.value;
    updateRealtime(path, {
      phrase,
      updatedAt: new Date().toISOString(),
      writerId: operator?.id,
      writerName: operator?.name
    })
      .catch((e) => {
        console.error(e);
        alert('수정사항을 저장할 수 없습니다.')
      });

    const changes = `DISPATCH DRIVING  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
    setText(phrase);
  }

  const handleApply: IconButtonProps['onClick'] = () => {
    if (text && !window.confirm('기존 입력 사항을 덮어쓰시겠습니까?')) return;
    const phrase = dispatch(tourTeamRows, area, ignoreProducts, noneGuideProducts, true, true, true);
    const updatableRecords = JSON.parse(JSON.stringify(tourTeamRows));
    const records = {updatedAt: new Date().toISOString(), records: updatableRecords};
    setText(phrase);
    (async () => {
      await updateRealtime(path, {
        phrase, records,
        updatedAt: new Date().toISOString(),
        writerId: operator?.id,
        writerName: operator?.name
      })
    })()
      .catch((e) => {
        console.error(e);
        alert('수정사항을 저장할 수 없습니다.')
      });

    const changes = `DISPATCH DRIVING by records  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
  }

  useEffect(() => {
    if (!_text) return;
    if (text !== _text)
      setText(_text);
  }, [_text]);

  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={'8px'}
        >
          <Typography variant={'h6'}>
            Driving
          </Typography>
          <IconButton onClick={handleApply}>
            <Icon>
              system_update_alt
            </Icon>
          </IconButton>
        </Box>
        <IconButton onClick={() => {
          copy(text).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <Stack>
        <Typography variant={'caption'}
                    color={new Date(tourUpdatedAt).getTime() > new Date(listenData?.updatedAt ?? '').getTime() ? 'error' : 'primary'}>
          {listenData?.writerName ?? ''} - {new Date(listenData?.updatedAt ?? '').toLocaleString()}
        </Typography>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        onChange={handleUpdatePhrase}
        value={text}
      />
    </Stack>
  )

}

function PrivateTourPhrase({tourTeamRows, area, date, ignoreProducts, noneGuideProducts, tourUpdatedAt}: {
  tourTeamRows: TourTeamRow[],
  area: Area,
  tourUpdatedAt: string,
  ignoreProducts: string[],
  noneGuideProducts: string[],
  date: string,
}) {

  const {operator} = useAuth();

  const path = `/operation/${date}/dispatch/${area.id}/private`;
  const {data} = useRead(path);
  const {data: listenData} = useListen<{ writerName?: string, writerId?: string, updatedAt?: string }>(path);
  const _text = (data as { phrase: string })?.phrase ?? '';
  const [text, setText] = useState<string>(_text);


  const handleUpdatePhrase: TextFieldProps['onChange'] = (e) => {
    const phrase = e.target.value;
    updateRealtime(path, {
      phrase,
      updatedAt: new Date().toISOString(),
      writerId: operator?.id,
      writerName: operator?.name
    }).catch((e) => {
      console.error(e);
      alert('수정사항을 저장할 수 없습니다.')
    });

    const changes = `DISPATCH PRIVATE  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
    setText(phrase);
  }

  const handleApply: IconButtonProps['onClick'] = () => {
    if (text && !window.confirm('기존 입력 사항을 덮어쓰시겠습니까?')) return;
    const phrase = dispatch(tourTeamRows, area, ignoreProducts, noneGuideProducts, false, true);
    const updatableRecords = JSON.parse(JSON.stringify(tourTeamRows));
    const records = {updatedAt: new Date().toISOString(), records: updatableRecords};
    setText(phrase);
    (async () => {
      await updateRealtime(path, {
        phrase,
        records,
        updatedAt: new Date().toISOString(),
        writerId: operator?.id,
        writerName: operator?.name
      });
    })()
      .catch((e) => {
        console.error(e);
        alert('수정사항을 저장할 수 없습니다.')
      });
    const changes = `DISPATCH PRIVATE by records  changed ${date}:${text}=>${phrase}]`
    logAction('OPERATION', 'UPDATE DISPATCH PHRASE', path, changes, {date, phrase, phraseBefore: text})
  }

  useEffect(() => {
    if (!_text) return;
    if (text !== _text)
      setText(_text);
  }, [_text]);


  return (
    <Stack
      gap={1}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={'8px'}
        >
          <Typography variant={'h6'}>
            Private
          </Typography>
          <IconButton onClick={handleApply}>
            <Icon>
              system_update_alt
            </Icon>
          </IconButton>
        </Box>
        <IconButton onClick={() => {
          copy(text).catch(console.error)
        }}>
          <Icon>copy_all</Icon>
        </IconButton>
      </Stack>
      <Stack>
        <Typography variant={'caption'}
                    color={new Date(tourUpdatedAt).getTime() > new Date(listenData?.updatedAt ?? '').getTime() ? 'error' : 'primary'}>
          {listenData?.writerName ?? ''} - {new Date(listenData?.updatedAt ?? '').toLocaleString()}
        </Typography>
      </Stack>
      <TextField
        multiline
        rows={30}
        fullWidth
        onChange={handleUpdatePhrase}
        value={text}
      />
    </Stack>
  )

}


function dispatch(tourTeamRows: TourTeamRow[], area: Area, ignoreDispatchProducts: string[], noneGuideProducts: string[], adjustTime?: boolean, addUpGuide?: boolean, driverGuide?: boolean) {
  const filteredTeamRows = tourTeamRows
    .filter((ttr) => !ttr.mergeTeam && !ignoreDispatchProducts.includes(ttr.product.name) && ttr.vehicleType !== '도보');
  if (filteredTeamRows.length <= 0) return ''

  const locale = area.locale;
  const isKorean = locale === 'ko';
  const date = filteredTeamRows[0].date
  const productTimeAggregation = aggregateByProductTime(filteredTeamRows);
  const formattedDate = dayjs(date).locale(locale).format(area.dateFormat)

  const modifyTime = (pickupTime: string) => {
    if (!adjustTime) return pickupTime;
    const [hours, min] = pickupTime.split(':')
    if (hours === '99') return `${pickupTime}`;
    const time = new Date()
    const parseHour = Number(min) < 15 ? Number(hours) - 1 : Number(hours);
    const parseMin = Number(min) < 15 ? 60 - (15 - Number(min)) : Number(min) - 15;
    time.setHours(parseHour, parseMin);
    return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`
  }

  const formatRow = (tourTeamRow: TourTeamRow, idx: number, tourTeamRows: TourTeamRow[]) => {
    const product = tourTeamRow.product;
    const isNoneGuide = noneGuideProducts.includes(product.name);

    const sameTour = tourTeamRows.filter((ttr) => ttr.product.id === product.id);
    const myIndexAmongUs = sameTour.findIndex((ttr) => ttr.teamId === tourTeamRow.teamId);
    const myNumb = sameTour.length > 1 ? `${myIndexAmongUs + 1}` : '';

    const pax = (tourTeamRow.people + (addUpGuide ? tourTeamRow.guides.length : 0)) + (isKorean ? '명' : 'pax');
    const startingPickup = tourTeamRow.pickups[0];
    const productPickups = orderedProductPickups(product)
      .map((pickup) => {
        const isWinter = product.winter;
        const time = (isWinter ? pickup.winterTime : pickup.time) ?? '99:99';
        return {
          time: modifyTime(time),
          place: pickup.place,
          name: pickup[locale] ?? pickup.place,
          order: pickup.order ?? 99
        }
      })
    const startingPickupIndex = productPickups.findIndex((p) => p.place === startingPickup?.place)
    const pickups = startingPickupIndex >= 0 ? productPickups.slice(startingPickupIndex, productPickups.length) : [];
    //ko가 언어와 무관하게 Full Name으로 쓰이고 있음
    const pickupPhrases = pickups.map(({name, time}) => `${time} ${name}`).join('\n');
    const driverGuide = tourTeamRow.guides.find(g => g.driverGuide);
    const unassignedGuide = isNoneGuide ? {name: isKorean ? '없음' : 'No Guide'} : {name: isKorean ? '미정' : 'Unassigned'};
    const representativeGuide = driverGuide ? (driverGuide ?? tourTeamRow.guides[0]) : (tourTeamRow.guides[0] ?? unassignedGuide);
    const guidePhrase = `${isKorean ? '가이드' : 'Guide'}: ${representativeGuide.name}${representativeGuide.tel ? `(${representativeGuide.tel})` : ''}`;
    const vehicleType = checkVehicleType(tourTeamRow.vehicleModel)[1] || tourTeamRow.vehicleModel;
    return [`${idx + 1}.${product.chat?.name?.ko ?? product.name}${myNumb} (${pax})`, pickupPhrases, vehicleType, guidePhrase].filter(s => s).join('\n')
  }
  const phrase = Object.values(productTimeAggregation).flat(1).map(formatRow).join('\n\n')
  return formattedDate + '\n\n' + phrase

}

function announcement(tourTeamRows: TourTeamRow[], area: Area, ignoreAnnouncementProducts: string[], ignoreFlagProducts: string[], noneGuideProducts: string[], skipFlags: string[]) {
  const filteredTourTeamRows = tourTeamRows.filter((ttr) => !ttr.mergeTeam && !ignoreAnnouncementProducts.includes(ttr.product.name));
  if (filteredTourTeamRows.length <= 0) return '';
  const locale = area.locale;
  const isKorean = locale === 'ko';
  const date = filteredTourTeamRows[0].date
  const timeAggregation = aggregateByTime(filteredTourTeamRows);
  const productTimeAggregation = aggregateByProductTime(filteredTourTeamRows);
  const formattedDate = dayjs(date).locale(locale).format(area.dateFormat)
  const textDivider = '\n--------------------------------\n'
  const formatTeam = (tourTeamRow: TourTeamRow) => {
    const product = tourTeamRow.product;
    const isNoneGuide = noneGuideProducts.includes(product.name);
    const teamNumber = tourTeamRow.teamCount > 1 ? tourTeamRow.teamNumb : '';
    const pickup = tourTeamRow.pickups[0] ? product.chat?.pickup?.[tourTeamRow.pickups[0].place] : undefined;
    const time = product?.winter ? pickup?.winterTime : pickup?.time;
    const guideNames = tourTeamRow.guides.length > 0
      ? tourTeamRow.guides.map(g =>
        (g.explorationGuide
          ? isKorean ? '답사 ' : 'Field ' : '')
        + (g.apprenticeshipGuide
          ? isKorean ? `수습${g.apprenticeships ?? 1} ` : 'Internship${g.apprenticeships ?? 1} '
          : '')
        + (g.driverGuide
          ? isKorean ? '운전 ' : 'Driving '
          : '') + `@${g.name}`).join(' ')
      : isNoneGuide ?
        isKorean ? '없음' : 'No Guide'
        : isKorean ? '미정' : 'Unassigned';
    const [_vehicle, _vehicleType, _driver] = checkVehicleType(tourTeamRow.vehicleModel);
    const vehicleType = tourTeamRow.vehicleType ?? _vehicleType;
    const vehicleNumber = tourTeamRow.vehicleNumber ?? '';
    const driver = tourTeamRow.driverName ?? _driver;
    const driverContact = tourTeamRow.driverContact ?? '';
    const memo = tourTeamRow.memo;
    return `${product.name}${teamNumber} - ${guideNames}` + '\n'
      + `${time} ${pickup?.[area.locale]}` + '\n'
      + `${vehicleType} ${vehicleNumber}`
      + `${driver ? `\n${driver} ${driverContact}` : ''}`
      + `${memo ? `\n${memo}` : ''}`;
  }

  const formatFlag = (flagTime: string, tourTeamRow: TourTeamRow, idx: number) => {
    const skipFlagNumbers = skipFlags.map((s) => Number.parseInt(s));
    const flags = new Array(TOTAL_FLAG_COUNT)
      .fill(1).map((i, idx) => i + idx)
      .filter(f => !skipFlagNumbers.includes(f) && (flagTime === '07:30' ? 20 : 0) <= f)
    const flagNumb = flags[idx];
    const product = tourTeamRow.product;
    const isNoneGuide = noneGuideProducts.includes(product.name);
    const unassignedName = isNoneGuide ? isKorean ? `${tourTeamRow.vehicleNumber}(버스번호)` : `${tourTeamRow.vehicleNumber}(Bus)` : isKorean ? '미정' : 'Unassigned';
    const guideNames = tourTeamRow.guides.filter(g => !g.apprenticeshipGuide && !g.driverGuide && !g.explorationGuide).map(g => {
      return isKorean ? `${g.name}(${g.nameEn})` : g.name
    }).join(' ')
    const guideName = tourTeamRow.guides?.length > 0 ? guideNames : unassignedName
    const pickupExpression = tourTeamRow.pickups[0]?.place ? area.pickupNameMap[tourTeamRow.pickups[0].place] : '';
    const teamNumber = tourTeamRow.teamCount > 1 ? tourTeamRow.teamNumb : '';
    return `${flagNumb}.${tourTeamRow.product.name}${teamNumber} - ${guideName} - ${pickupExpression}`
  }

  const expression = Object.entries(timeAggregation)
    .sort(([aTime], [bTime]) => {
      const a = aTime.padStart(2, '0');
      const b = bTime.padStart(2, '0');
      return a > b ? 1 : -1;
    })
    .map(([_, tourTeamRows]) => {
      const teamPhrases = tourTeamRows.map(formatTeam).join('\n\n')
      return textDivider + teamPhrases
    }).join('')

  const flagExpression = area.disableFlag
    ? ''
    : Object.entries(productTimeAggregation)
      .sort(([aTime], [bTime]) => {
        const a = aTime.padStart(2, '0');
        const b = bTime.padStart(2, '0');
        return a > b ? 1 : -1;
      })
      .map(([flagTime, tourTeamRows]) => {
        const teamPhrases = tourTeamRows
          .filter((r) => !ignoreFlagProducts.includes(r.product.name))
          .map((tourTeamRow, idx) => formatFlag(flagTime, tourTeamRow, idx)).join('\n')
        const timePhrase = flagTime + ' flag';
        return timePhrase + '\n' + teamPhrases
      }).join('\n\n')

  return formattedDate + expression + area.announcement + flagExpression
}


function aggregateByTime(tourTeamRows: TourTeamRow[]) {
  return tourTeamRows.reduce((result, ttr) => {
    const time = ttr.pickups[0]?.time ?? '99:99';
    if (!(time in result)) {
      result[time] = [];
    }
    result[time] = [...result[time], ttr].sort((a, b) => {
      if (a.product.id > b.product.id) return 1;
      if (a.product.id < b.product.id) return -1;
      if (a.product.id === b.product.id) {
        if (a.teamNumb > b.teamNumb) return 1;
        if (a.teamNumb < b.teamNumb) return -1;
      }
      return 0
    });
    return result;
  }, {} as { [time: string]: typeof tourTeamRows })
}

function aggregateByProductTime(tourTeamRows: TourTeamRow[]) {
  return tourTeamRows.reduce((result, ttr) => {
    const time = representativePickups(ttr.product)?.time ?? '99:99';
    if (!(time in result)) {
      result[time] = [];
    }
    result[time] = [...result[time], ttr].sort((a, b) => {
      if (a.product.id > b.product.id) return 1;
      if (a.product.id < b.product.id) return -1;
      if (a.product.id === b.product.id) {
        if (a.teamNumb > b.teamNumb) return 1;
        if (a.teamNumb < b.teamNumb) return -1;
      }
      return 0
    });
    return result;
  }, {} as { [time: string]: typeof tourTeamRows })
}

function orderedProductPickups(product: Product) {
  return Object.values(product.chat?.pickup ?? {})
    .filter(p => p.use) //이용중 픽업만 처리
    .sort(({order: aOrder}, {order: bOrder}) => {
      return (aOrder ?? 99) > (bOrder ?? 99) ? 1 : -1;
    })
}

function representativePickups(product: Product) {
  const pickups = orderedProductPickups(product).map((pickup) => {
    const time = (product.winter ? pickup.winterTime : pickup.time) ?? '99:99';
    return {place: pickup.place, time};
  })
  return pickups.find((p) => p.place === 'Myungdong') ?? pickups[1] ?? pickups[0];
}
