type Option = {
  option: string,
  people: number,
}

export default class AgencyRow {
  agency: string;
  product: string;
  agencyCode: string;
  clientName: string;
  date: string;
  people: number;
  options: Option[];
  pickupPlace?: string;
  stroller?: string;
  language?: string;
  email?: string;
  tel?: string;

  constructor(raw: RawAgencyRow) {
    this.agency = raw.agency;
    this.agencyCode = raw.agencyCode;
    this.clientName = raw.clientName.replace(/\(.+\)/gi, '');
    this.date = raw.date;
    this.product = raw.product;
    this.people = raw.people;
    this.options = raw.options;
    this.pickupPlace = raw.pickupPlace;
    this.stroller = raw.stroller;
    this.language = raw.language;
    this.email = raw.email;
    this.tel = raw.tel;
  }

}

export type RawAgencyRow = {
  agency: string,
  agencyCode: string,
  clientName: string,
  product: string,
  date: string,
  people: number,
  options: { option: string, people: number }[],
  pickupPlace?: string,
  stroller?: string,
  language?: string,
  email?: string,
  tel?: string,
  others?: { [property: string]: any }
};

