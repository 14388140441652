import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {ReservationRow} from "../type";
import {
  DataGridPro, DataGridProProps,
  GridColDef, gridFilteredDescendantCountLookupSelector, gridFilterModelSelector,
  GridHeaderFilterCellProps, GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel, useGridApiContext, useGridSelector
} from "@mui/x-data-grid-pro";
import React, {useCallback, useMemo, useState} from "react";
import {InputLabel, Select, SelectChangeEvent, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {Row} from "write-excel-file";
import Button from "@mui/material/Button";

type OptionCountRow = {
  id: string,
  hierarchy: string[],
  option: string,
  count: number,
}

function ProductFilter(props: GridHeaderFilterCellProps & { productNames: string[] }) {
  const {colDef, productNames} = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({field}) => field === colDef.field),
    [colDef.field, filterModel.items],
  );

  const getDefaultFilter = (field: string) => ({field, operator: 'isAnyOf'});

  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] ?? getDefaultFilter(colDef.field)),
        value: event.target.value,
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? [];
  const label = 'is any of';

  return (
    <FormControl variant="standard" sx={{m: 1, minWidth: 120}} fullWidth>
      <InputLabel id="select-is-admin-label">Select</InputLabel>
      <Select
        labelId="select-is-admin-label"
        id="select-is-admin"
        multiple
        value={value}
        onChange={handleChange}
        label={'Select'}
      >
        {
          productNames.map((p, idx) => (
            <MenuItem key={p + idx} value={p}>{p}</MenuItem>
          ))
        }

      </Select>
    </FormControl>
  );
}

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const {id, field, rowNode, formattedValue,} = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick = (event: any) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };
  return (
    <Box sx={{ml: rowNode.depth * 4}}>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button onClick={handleClick} tabIndex={-1} size="small">
            {formattedValue}
          </Button>
        ) : (
          <span/>
        )}
      </div>
    </Box>
  );
}



export default function CountOptions(props: {
  loadKey: string,
  onRefresh: () => void,
  reservationRows: ReservationRow[],
  onClickReservationRow: (row: GridRowParams) => void,
  onRowSelectionModel: (newRowSelectionModel: GridRowSelectionModel) => void,
  rowSelectionModel: GridRowSelectionModel,
}) {

  const {loadKey, onRefresh, reservationRows, onClickReservationRow, onRowSelectionModel, rowSelectionModel} = props;
  const [keyword, setKeyword] = useState<string | null>(null);
  const [openLevel, setOpenLevel] = useState<number>(6);
  const [groupByProduct, setGroupByProduct] = useState<boolean>(true);

  const filteredReservationRows = useMemo(() => (
    reservationRows.filter((r) => {
      return !keyword || `${r.clientName}${r.agencyCode}${r.email}${r.tel}${r.messenger}${r.id}${r.memo}`.toLowerCase().includes(keyword.toLowerCase())
    })), [reservationRows, keyword]);

  const productNamesIncluded = useMemo(() => (
    [...new Set(reservationRows.map((r) => {
      return r.product
    })).values()].sort()), [reservationRows, keyword]);
  const selectedReservations = reservationRows.filter((r) => rowSelectionModel.includes(r.id));

  const handleToggleOpenLevel = () => {
    setOpenLevel((prev) => {
      switch (prev) {
        case 2:
          return 10;
        default:
          return prev - 4;
      }
    })
  }

  const handleToggleGroup = () => {
    setGroupByProduct((group) => {
      return !group;
    })
  }

  const getTreeDataPath = useCallback((row: OptionCountRow) => row.hierarchy, [])


  const counts = useMemo(() => {
    const optionCounts: { [option: string]: number } = {};
    const optionCountsByProduct: { [product: string]: { [option: string]: number } } = {};
    selectedReservations.forEach((row) => {
      const product = row.product;
      const options = row.option.split('\n')
        .map((os) => {
          const regexMatches = /(.+)\((\d+)\)/gi.exec(os);
          if (!regexMatches) return {option: "Ignore", people: 0};
          const option = regexMatches[1];
          const people = Number.parseInt(regexMatches[2]);
          return ({option, people})
        })
        .filter(o => !!o.people);
      options.forEach(({option, people}) => {
        if (!optionCounts[option]) optionCounts[option] = 0;
        if (!optionCountsByProduct[product]) optionCountsByProduct[product] = {};
        if (!optionCountsByProduct[product][option]) optionCountsByProduct[product][option] = 0;

        optionCounts[option] += people;
        optionCountsByProduct[product][option] += people;
      })
    })
    return {
      optionCountsByProduct,
      optionCounts
    };
  }, [selectedReservations]);

  const rows = useMemo(() => {
    if (groupByProduct) {
      const optionCountRows: OptionCountRow[] = Object.entries(counts.optionCountsByProduct)
        .flatMap(([product, optionCounts]): OptionCountRow[] => {
          return Object.entries(optionCounts).map(([option, count]): OptionCountRow => ({
            id: `${product}-${option}`,
            hierarchy: [product, option],
            option,
            count
          }))
        })
      return optionCountRows;
    }

    return Object.entries(counts.optionCounts).map(([option, count]): OptionCountRow => ({
      id: `${option}`,
      hierarchy: [option],
      option,
      count
    }))
  }, [counts, groupByProduct]);

  const sortedRows = useMemo(() => {
    return rows.sort((a, b) => a.id > b.id ? 1 : -1);
  }, [rows]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Group',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params}/>
  };


  const ReservationColumns: GridColDef[] = [
    {field: 'memo', headerName: 'MEMO', minWidth: 300, align: 'center', headerAlign: 'center'},
    {field: 'date', headerName: 'DATE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {
      field: 'product',
      type: 'singleSelect',
      headerName: 'PRODUCT',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderHeaderFilter: (params) => <ProductFilter {...params} productNames={productNamesIncluded}/>
    },
    {field: 'pickupPlace', headerName: 'PICKUP', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'people', headerName: 'PEOPLE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {
      field: 'option', headerName: 'OPTION', minWidth: 500, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return (<Typography variant={'body2'} whiteSpace={'pre-wrap'}>{params.formattedValue}</Typography>)
      }
    },
    {field: 'stroller', headerName: 'STROLLER', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'clientName', headerName: 'NAME', minWidth: 200, align: 'center', headerAlign: 'center'},
    {field: 'nationality', headerName: 'NATIONALITY', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'language', headerName: 'LANGUAGE', minWidth: 100, align: 'center', headerAlign: 'center'},
    {field: 'agency', headerName: 'AGENCY', minWidth: 50, align: 'center', headerAlign: 'center'},
    {field: 'agencyCode', headerName: 'AGENCY CODE', minWidth: 50, align: 'center', headerAlign: 'center'},
  ]


  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={openLevel}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  height: '70vh',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    sx={{
                      width: '200px'
                    }}
                    variant={'standard'}
                    placeholder={'search'}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value ?? null)}
                  />
                  <IconButton onClick={handleToggleOpenLevel}>
                    <Icon>
                      {
                        openLevel === 2
                          ? 'arrow_forward_ios'
                          : 'arrow_back_ios'
                      }
                    </Icon>
                  </IconButton>
                </Box>
                <DataGridPro
                  checkboxSelection
                  unstable_headerFilters
                  columns={ReservationColumns}
                  rows={filteredReservationRows}
                  getRowHeight={(params) => {
                    const options = (params.model.option ?? '').split('\n');
                    return 44 + Math.max(1, options.length) * 20;
                  }}
                  onRowClick={onClickReservationRow}
                  onRowSelectionModelChange={onRowSelectionModel}
                  rowSelectionModel={rowSelectionModel}
                  disableRowSelectionOnClick
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12 - openLevel}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  height: '70vh',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end'
                  }}
                >
                  <FormControlLabel
                    label={'Product Grouping'}
                    control={<Switch checked={groupByProduct} onChange={handleToggleGroup}/>}
                  />
                </Box>
                <DataGridPro
                  treeData={groupByProduct}
                  getTreeDataPath={getTreeDataPath}
                  defaultGroupingExpansionDepth={2}
                  rows={sortedRows}
                  groupingColDef={groupingColDef}
                  columns={[
                    {
                      field: 'option',
                      headerName: 'OPTION',
                      minWidth: 500,
                      headerAlign: 'left'
                    },
                    {
                      field: 'count',
                      headerName: 'COUNT',
                      minWidth: 500,
                      headerAlign: 'left'
                    }
                  ]}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>

  )
}
