import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";
import {findSkiOptions} from "./ski";


export default async function parseKKDayFiles(file: File | Blob): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'KK';
    const agencyCode = cleanString(row['Booking no.']);
    const orderStatus = cleanString(row['Order Status']);
    if (!agencyCode) return result;
    if (orderStatus.includes('Canceled')) return result;

    const clientName = cleanString(row['Passport First Name (English)'] + ' ' + cleanString(row['Passport Surname Name (English)']));
    const product = [cleanString(row['Product\'s Name']), cleanString(row['Package Name'])].join(' ');
    const people = toNumber(row['Quantity']);
    const date = cleanString(row['Departure Date']);
    const email = cleanString(row['Email Address']);
    const tel = cleanString(row['Buyer\'s Phone Number'])

    const option = cleanString(row['Package Name']);
    const specifications = [row['Specification 1'], row['Specification 2'], row['Specification 3']]
    const pickupPlace = cleanString(row['Pick-up location']) + specifications.join(' ');

    const language = cleanString(row['Tour Language']).replace(/zh.*/gi, 'CN').replace(/en.*/gi, 'EN');

    const options = [option, ...specifications]
      .filter(specification => specification && !(specification?.match(/(Meeting|Pick|接送)/gi)))
      .map(specification => {
        const optionable = /(?:.+?[：:])?(.+)/.exec(specification)?.[1]
        return optionable?.trim() ?? '';
      })
      .map(cleanString)
      .flatMap((specification) => findSkiOptions('KK', cleanString(row['Product\'s Name']), specification, cleanString(row['Package Name'])) ?? specification)
      .map((specification) => {
        const lowerSpecification = specification.toLowerCase();
        if (lowerSpecification.includes('early return')) {
          return 'EARLY RETURN' as string;
        }
        if (lowerSpecification.includes('late return')) {
          return 'LATE RETURN' as string;
        }
        if (lowerSpecification.includes('sky capsule')) {
          return 'CAPSULE' as string;
        }
        if (lowerSpecification.includes('t-money')) {
          return 'T-MONEY' as string;
        }
        return specification;
      })

    console.log(options);

    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      language,
      product,
      date,
      people,
      email,
      tel,
      pickupPlace,
      stroller: 'X',
      options: options.map(option => ({option, people})),
    };

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array", raw: true})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames

  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet, {range: 1}) as any[];
  return data;
}

function cleanString(data?: any): string {
  const escaped = data?.toString().replace(/.+[\]】]/gi, '');
  return escaped?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}

