import * as XLSX from "xlsx";
import AgencyRow, {RawAgencyRow} from "./agency";


export default async function parseTrazyFile(file: File | Blob): Promise<AgencyRow[]> {
  const parsed = await parseFileData(file);
  const aggregation = parsed.reduce((result, row) => {
    const agency = 'T';
    const agencyCode = cleanString(row['Reservation Code']);
    if (!agencyCode) return result;
    const date = toDashDate(row['Reserve Date']);
    const option = cleanString(row['Option Name']);
    const product = (cleanString(row['Activity'])) + ' ' + option;
    const clientName = cleanString(row['Username']);
    const {people, options: peopleOptions} = handlePeopleOption(row['Quantity Detail']);
    const pickupPlace = option;
    const requiredInformation = cleanString(row['Required Information'] ?? '');
    const language = 'EN';
    const options = [option]
      .concat(requiredInformation.split('\n').filter((ri, index) => ri !== 'No' && ri !== 'English' && index > 0))
      .filter(o => !!o)
      .map(cleanString)

    const obj: RawAgencyRow = {
      agency,
      agencyCode,
      clientName,
      product,
      date,
      people,
      pickupPlace,
      language,
      stroller: 'X',
      options: options.map(option => ({option, people})).concat(...peopleOptions),
    };

    result[agencyCode] = obj;
    return result;
  }, {} as {
    [agencyCode: string]: RawAgencyRow
  });
  return Object.values(aggregation);
}

const parseFileData = async (file: Blob | File) => {
  const binary = await file.arrayBuffer()
  const workbook = XLSX.read(binary, {type: "array"})
  const sheets = workbook.Sheets
  const sheetNames = workbook.SheetNames
  const firstSheet = sheets[sheetNames[0]]
  const data = XLSX.utils.sheet_to_json(firstSheet) as any[];
  return data;
}

function handlePeopleOption(details: string) {
  const eraseLinebreak = details.replace('\n', '');
  const splited = eraseLinebreak.split(',').map(s => s.trim().replace(/ ?\(.+\) ?/gi, ' '));
  return splited.reduce((result, s) => {
    const regexResult = /(.+)\s?(\d+)/gi.exec(s);
    if (!regexResult) return result;
    const type = regexResult[1].trim();
    const number = Number.parseInt(regexResult[2]);
    if (type.toLowerCase().includes('person') || type.toLowerCase().includes('adult')) {
      result.people += number;
      result.adult += number;
      return result;
    }
    if (type.toLowerCase().includes('kid') || type.toLowerCase().includes('child')) {
      result.people += number;
      result.kid += number;
      return result;
    }
    result.options.push({option: type, people: number});
    return result;
  }, {
    people: 0,
    adult: 0,
    kid: 0,
    options: [],
  } as {
    options: { option: string; people: number }[],
    people: number,
    adult: number,
    kid: number
  });
}

function toDashDate(datelike: number | string) {
  const dateStr = datelike.toString();
  const regexResult = /(\d{4})(\d{2})(\d{2})/gi.exec(dateStr);
  const year = regexResult?.[1];
  const month = regexResult?.[2];
  const day = regexResult?.[3];
  return `${year}-${month}-${day}`;
}

function cleanString(data?: any): string {
  const escaped = data?.toString().replace(/.+[\]】]/gi, '');
  return escaped?.trim() || ''
}

function toNumber(numberLike?: string | number) {
  if (typeof numberLike === 'number') return numberLike;
  return Number(cleanString(numberLike) || 0);
}

